import { HigFile, NotificationContext, useCancellablePromise } from '@mid-react-common/common';
import { Chip, Typography } from '@mui/material';
import { GridColDef, GridComparatorFn, GridRenderCellParams, GridRowId, GridRowParams } from '@weave-mui/data-grid';
import { format, parseISO } from 'date-fns';
import { debounce } from 'lodash';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { BIM360Document, ENVIRONMENT, Environment } from 'mid-types';
import { ServiceConfigMap } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import { MODELS_FOLDER_CONTENT_TIME_FORMAT, MODELS_FOLDER_FILENAME_EXTENSION } from '../../../global/constants/model';
import text from '../../../global/text.json';
import { ModelsFolderContentProps } from './ModelsFolderContent';
import { FilenameCell, ModelsFileName } from './ModelsFolderContent.style';
import { FolderContentRow } from './ModelsFolderContent.types';
const modelsFolderText = text.modelsFolderContent;

const RenderFilenameCell = ({ id, value }: GridRenderCellParams): React.ReactNode => {
  const { currentlyOpenModel } = useContext(ModelSelectionContext);

  return (
    <FilenameCell>
      <HigFile />
      <ModelsFileName>
        <Typography variant="body2" noWrap>
          {value}
        </Typography>
      </ModelsFileName>
      {currentlyOpenModel?.id === id && (
        <Chip color="primary" variant="outlined" label={<strong>{modelsFolderText.opened}</strong>} size="small" />
      )}
    </FilenameCell>
  );
};

const RenderVersionCell = ({ value }: GridRenderCellParams): React.ReactNode => <Chip label={value} size="small" />;

export const modifiedTimeComparator: GridComparatorFn<string> = (firstModifiedTime, secondModifiedTime) => {
  if (!firstModifiedTime) {
    return -1;
  }

  if (!secondModifiedTime) {
    return 1;
  }

  const firstDate = new Date(firstModifiedTime);
  const secondDate = new Date(secondModifiedTime);

  return firstDate.getTime() - secondDate.getTime();
};

const columns: GridColDef[] = [
  {
    field: 'fileName',
    headerName: modelsFolderText.name,
    width: 370,
    renderCell: RenderFilenameCell,
  },
  {
    field: 'version',
    headerName: modelsFolderText.version,
    width: 70,
    renderCell: RenderVersionCell,
    align: 'center',
  },
  {
    field: 'modifiedOn',
    headerName: modelsFolderText.lastUpdated,
    width: 150,
    sortComparator: modifiedTimeComparator,
  },
];

const transformLMVModelFileListInFolderContent = (lmvModelFileList: BIM360Document[]): FolderContentRow[] =>
  lmvModelFileList
    .filter((lmvModelFile) => lmvModelFile.file_name.endsWith(MODELS_FOLDER_FILENAME_EXTENSION))
    .map((lmvModelFile: BIM360Document) => {
      const { id, urn, name, latest_version_create_time, latest_version } = lmvModelFile;

      const parsedUpdatedTime = parseISO(latest_version_create_time);
      const formattedUpdatedTime = format(parsedUpdatedTime, MODELS_FOLDER_CONTENT_TIME_FORMAT);
      const lmvModelFileId = btoa(lmvModelFile.current_version.bubble_urn).replace(/\//g, '_');

      return {
        id,
        urn,
        fileName: name,
        version: latest_version.toString(),
        modifiedOn: formattedUpdatedTime,
        lmvModelFileId,
      };
    });

type UseModelsFolderContentState = {
  filteredProductFoldersContent: FolderContentRow[];
  columns: GridColDef[];
  selectedModelId: GridRowId | undefined;
  handleOpenFoldersInDocsClick: () => void;
  handleFilterFilesByName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRowClick: (rowParams: GridRowParams<FolderContentRow>) => Promise<void>;
};

const useModelsFolderContent = ({
  folderUrn,
  lmvModelFiles,
  selectedModelFolder,
  setIsPreviewLoading,
  setSelectedModelFolder,
}: ModelsFolderContentProps): UseModelsFolderContentState => {
  const { projectId } = useContext(AccountProjectContext);
  const { productFoldersContent, setProductFoldersContent, selectedModelId, setSelectedModelId } =
    useContext(ModelSelectionContext);
  const { logAndShowNotification } = useContext(NotificationContext);
  const cancellablePromise = useCancellablePromise();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredProductFoldersContent, setFilteredProductFoldersContent] = useState<FolderContentRow[]>([]);

  const { itemUrn, details } = useParams<{ itemUrn: string; details?: string }>();
  const navigate = useNavigate();

  const selectItem = useCallback(
    async (rowData: FolderContentRow) => {
      try {
        setIsPreviewLoading(true);
        setSelectedModelId(rowData.id);

        const thumbnail = await cancellablePromise(getForgeApiServiceInstance().getThumbnail(rowData.lmvModelFileId));

        const folderContentRow: FolderContentRow = {
          ...rowData,
          thumbnail,
        };

        setSelectedModelFolder(folderContentRow);
      } catch (error) {
        logAndShowNotification({
          message: modelsFolderText.failedToLoadThumbnail,
        });
      } finally {
        setIsPreviewLoading(false);
      }
    },
    [setIsPreviewLoading, setSelectedModelId, setSelectedModelFolder, logAndShowNotification, cancellablePromise],
  );

  const handleRowClick = async (rowParams: GridRowParams<FolderContentRow>) => {
    if (selectedModelFolder?.id === rowParams.id) {
      return;
    }
    selectItem(rowParams.row);
  };

  const handleOpenFoldersInDocsClick = () => {
    const currentEnv = (import.meta.env.VITE_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;
    const url = ServiceConfigMap.DOCUMENTS_MANAGER_API[currentEnv].api + '/' + projectId + '/folders/' + folderUrn;
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const handleFilterFilesByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const debouncedHandleFilterFilesByName = debounce(handleFilterFilesByName, 250);

  const filterProductFoldersContentData = (query: string, productFoldersContent: FolderContentRow[]) => {
    if (!query) {
      return productFoldersContent;
    }
    return productFoldersContent.filter((folderContent) =>
      folderContent.fileName.toLowerCase().includes(query.toLowerCase()),
    );
  };

  useEffect(() => {
    setFilteredProductFoldersContent(filterProductFoldersContentData(searchQuery, productFoldersContent));
  }, [productFoldersContent, searchQuery]);

  useEffect(() => {
    if (lmvModelFiles) {
      const transformedFolderContent = transformLMVModelFileListInFolderContent(lmvModelFiles);
      setProductFoldersContent(transformedFolderContent);

      const itemToSelect = transformedFolderContent.find((item) => item.id === itemUrn);

      // second condition prevent "opening" the details panel twice upon row click
      if (itemToSelect && selectedModelId !== itemToSelect.id) {
        setSelectedModelId(itemToSelect.id);

        if (details === 'details') {
          selectItem(itemToSelect);
        }
      }

      // itemUrn in URL is not valid (can't be found)
      if (itemUrn && !itemToSelect) {
        logAndShowNotification({
          message: text.modelsFolderContent.failedToFindSelectedItem,
          severity: 'error',
        });

        navigate('..');
      }
    }
    // selectedModelId is deliberately not included in the deps to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lmvModelFiles, itemUrn, details, setProductFoldersContent, setSelectedModelId, selectItem]);

  return {
    filteredProductFoldersContent,
    columns,
    selectedModelId,
    handleOpenFoldersInDocsClick,
    handleFilterFilesByName: debouncedHandleFilterFilesByName,
    handleRowClick,
  };
};

export default useModelsFolderContent;
