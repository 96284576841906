import {
  HubProjectFolderContent,
  hubFileTypes,
  ForgeDMProjectFolder,
  ProjectFolder,
  contentTypes,
  HubProjectFolderContentLinks,
  FolderPermissionResult,
  COMMAND_TYPE,
  CHECK_PERMISSION_TYPE,
  folderPermissionActions,
  FolderPermissionDetail,
} from 'mid-types';

/**
 * Converts a ForgeDMProjectFolder to a ProjectFolder
 * @param data the data to transform
 * @returns a ProjectFolder
 */
const toProjectFolder = (data: ForgeDMProjectFolder): ProjectFolder => {
  const { urn, title, hidden, deleted, path, ...restOfData } = data;

  return {
    urn,
    title,
    path,
    hidden,
    projectId: restOfData.project_id,
    parentUrn: restOfData.parent_urn,
    hasSubfolders: restOfData.has_subfolders,
    deleted,
    folderType: restOfData.folder_type,
    isRoot: restOfData.is_root,
    viewOption: restOfData.view_option,
    permissionType: restOfData.permission_type,
    isSystemFolder: restOfData.is_system_folder,
  } as ProjectFolder;
};

// FIX/IMPROVE: https://jira.autodesk.com/browse/TRADES-4319
// THE MOCK FOLDERS ARE NAMED BASED ON
// THE NESTING LOGIC BELOW.
// The letters indicate the nesting depth a/b/c etc.,
// The numbers indicate sibling folders 1a1, 1a2, 2a, 2a1
// /mockFolder1
/*
  /mockFolder2
    /mockFolder2a
      /mockFolder2b
  /mockFolder1
    /mockFolder1a
      /mockFolder1b etc.,
        /mockFolder1c etc.,
    /mockFolder1a1
      /mockFolder1b1 */

export const mockFolder1b: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: 'prefix.mock-folder1a-urn',
  title: 'Plans 1b',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder1b-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: false,
  path: 'mock-folder1-urn/mock-folder1a-urn/mock-folder1b-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockFolder1b2: ForgeDMProjectFolder = {
  permission_type: 'view_only',
  parent_urn: 'prefix.mock-folder1a-urn',
  title: 'Plans 1b2',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder1b2-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: false,
  path: 'mock-folder1-urn/mock-folder1a-urn/mock-folder1b2-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['view', 'collaborate'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockFolder1a: ForgeDMProjectFolder = {
  permission_type: 'edit',
  parent_urn: 'prefix.mock-folder1-urn',
  title: 'Plans 1a',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder1a-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: false,
  path: 'mock-folder1-urn/mock-folder1a-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit'],
  is_system_folder: true,
  has_subfolders: true,
  folders: [mockFolder1b, mockFolder1b2],
};

export const mockFolder1: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: '',
  title: 'Plans 1',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder1-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: true,
  path: 'mock-folder1-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: true,
  folders: [mockFolder1a, mockFolder1b, mockFolder1b2],
};

export const mockFolder2a: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: 'prefix.mock-folder2-urn',
  title: 'Plans 2a',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder2a-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: false,
  path: 'mock-folder2-urn/mock-folder2a-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockFolder2b: ForgeDMProjectFolder = {
  permission_type: 'publish',
  parent_urn: 'prefix.mock-folder2-urn',
  title: 'Plans 2b',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder2b-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: false,
  path: 'mock-folder2-urn/mock-folder2b-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

// FIX: this mock data doesn't follow the nesting logic of the other mock-data.
// https://jira.autodesk.com/browse/TRADES-4319
export const mockFolder1c: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: mockFolder1a.urn,
  title: 'Plans 1c',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'mock-folder1c-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: false,
  path: 'mock-folder1c-path',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockFolder2: ForgeDMProjectFolder = {
  permission_type: 'edit',
  parent_urn: '',
  title: 'Plans 2',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder2-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: true,
  path: 'mock-folder2-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit'],
  is_system_folder: true,
  has_subfolders: true,
  folders: [mockFolder2a, mockFolder2b],
};

export const mockNewFolder: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: 'mock-folder1-urn',
  title: 'Mock New Folder',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'mock-new-folder-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: false,
  path: 'mock-folder1-path',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockProjectFoldersURNMap: { [urn: string]: ForgeDMProjectFolder } = {
  [mockFolder1b.urn]: mockFolder1b,
  [mockFolder1b2.urn]: mockFolder1b2,
  [mockFolder1a.urn]: mockFolder1a,
  [mockFolder1.urn]: mockFolder1,
  [mockFolder2a.urn]: mockFolder2a,
  [mockFolder2b.urn]: mockFolder2b,
  [mockFolder1c.urn]: mockFolder1c,
  [mockFolder2.urn]: mockFolder2,
  [mockNewFolder.urn]: mockNewFolder,
};

export const mockProjectFolder1: ProjectFolder = toProjectFolder(mockFolder1);
export const mockProjectFolder1a: ProjectFolder = toProjectFolder(mockFolder1a);
export const mockProjectFolder1b: ProjectFolder = toProjectFolder(mockFolder1b);
export const mockProjectFolder1c: ProjectFolder = toProjectFolder(mockFolder1c);
export const mockProjectFolder2: ProjectFolder = toProjectFolder(mockFolder2);
export const mockProjectFolder2a: ProjectFolder = toProjectFolder(mockFolder2a);
export const mockProjectFolders: ProjectFolder[] = [mockProjectFolder1, mockProjectFolder2];

export const mockFolderTree1: ForgeDMProjectFolder = {
  ...mockFolder1,
  folders: [
    mockFolder1a,
    {
      ...mockFolder1b,
      folders: [mockFolder2, mockFolder2a],
    },
  ],
};

export const mockFolderTree2: ForgeDMProjectFolder[] = [
  {
    ...mockFolder1,
    folders: [{ ...mockFolder1a, folders: [] }, mockFolder1b],
  },
  mockFolder2,
];

export const getSubFolderMockResponse = (folderUrn: string): ForgeDMProjectFolder[] => {
  const folderNestingMap: { [key: string]: ForgeDMProjectFolder[] } = {
    [mockFolder1.urn]: [mockFolder1a],
    [mockFolder1a.urn]: [mockFolder1b, mockFolder1b2],
    [mockFolder2.urn]: [mockFolder2a, mockFolder2b],
  };
  return folderNestingMap[folderUrn];
};

/** Beginning of mock folder structure for Data Management V2 */

const mockHubProjectFolerContentLinks: HubProjectFolderContentLinks = {
  links: { self: { href: '' } },
  relationships: {
    contents: { links: { related: { href: '' } } },
    parent: {
      data: {
        type: 'folders',
        id: 'folder-id',
      },
      links: { related: { href: '' } },
    },
    refs: {
      links: { self: { href: '' }, related: { href: '' } },
    },
    links: {
      links: { self: { href: '' } },
    },
  },
};

export const mockHubProjectFolder1: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-folder1-urn',
  attributes: {
    name: 'group-plan-1',
    displayName: 'Plans 1',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 2,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: true,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const mockHubProjectFolder1a: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-folder1a-urn',
  attributes: {
    name: 'group-plan-1a',
    displayName: 'Plans 1a',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 2,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: false,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const mockHubProjectFolder1b: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-folder1b-urn',
  attributes: {
    name: 'group-plan-1b',
    displayName: 'Plans 1b',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 0,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: false,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const mockHubProjectFolder1b2: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-folder1b2-urn',
  attributes: {
    name: 'group-plan-1b2',
    displayName: 'Plans 1b2',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 0,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: false,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const mockHubProjectFolder2: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-folder2-urn',
  attributes: {
    name: 'group-plan-2',
    displayName: 'Plans 2',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 2,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: true,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const mockHubProjectFolder2a: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-folder2a-urn',
  attributes: {
    name: 'group-plan-2a',
    displayName: 'Plans 2a',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 1,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: false,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const mockHubProjectFolder2b: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-folder2b-urn',
  attributes: {
    name: 'group-plan-2b',
    displayName: 'Plans 2b',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 1,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: false,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const mockNewHubProjectFolder1: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-new-folder-urn-1',
  attributes: {
    name: 'group-new-folder',
    displayName: 'New Folder 1',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 1,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: false,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const mockNewHubProjectFolder2: HubProjectFolderContent = {
  type: contentTypes.FOLDERS,
  id: 'prefix.mock-new-folder-urn-2',
  attributes: {
    name: 'group-new-folder',
    displayName: 'New Folder 2',
    createTime: '2022-01-01T01:00:00.0000000Z',
    createUserId: '',
    createUserName: '',
    lastModifiedTime: '2022-01-01T01:00:00.0000000Z',
    lastModifiedUserId: 'TEST_ID',
    lastModifiedUserName: 'Test User',
    lastModifiedTimeRollup: '2022-01-01T01:00:00.0000000Z',
    objectCount: 1,
    hidden: false,
    extension: {
      type: hubFileTypes.BIM360_FOLDER,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        visibleTypes: [hubFileTypes.BIM360_FILE],
        actions: [],
        allowedTypes: [hubFileTypes.BIM360_FILE, hubFileTypes.BIM360_FOLDER],
        isRoot: false,
        folderType: 'normal',
        namingStandardIds: [],
      },
    },
  },
  ...mockHubProjectFolerContentLinks,
};

export const getHubProjectFolderContentMockResponse = (folderUrn: string): HubProjectFolderContent[] => {
  const folderNestingMap: { [key: string]: HubProjectFolderContent[] } = {
    [mockHubProjectFolder1.id]: [mockHubProjectFolder1a],
    [mockHubProjectFolder1a.id]: [mockHubProjectFolder1b, mockHubProjectFolder1b2],
    [mockHubProjectFolder2.id]: [mockHubProjectFolder2a, mockHubProjectFolder2b],
    [mockHubProjectFolder1b.id]: [],
    [mockHubProjectFolder1b2.id]: [],
    [mockHubProjectFolder2a.id]: [],
    [mockHubProjectFolder2b.id]: [],
  };
  return folderNestingMap[folderUrn];
};

const mockHubProjectFolders = [
  mockHubProjectFolder1,
  mockHubProjectFolder1a,
  mockHubProjectFolder1b,
  mockHubProjectFolder1b2,
  mockHubProjectFolder2,
  mockHubProjectFolder2a,
  mockHubProjectFolder2b,
];

export const getHubProjectFolderMockResponse = (folderUrn: string): HubProjectFolderContent => {
  const folder = mockHubProjectFolders.find((folder) => folder.id === folderUrn);

  return folder || mockHubProjectFolder1;
};

export const mockFolder1PermissionDetail: FolderPermissionDetail = {
  id: mockHubProjectFolder1.id,
  type: contentTypes.FOLDERS,
  details: {
    create: true,
    upload: true,
  },
  permission: true,
};

export const mockFolder2PermissionDetail: FolderPermissionDetail = {
  id: mockHubProjectFolder2.id,
  type: contentTypes.FOLDERS,
  details: {
    create: true,
    upload: true,
  },
  permission: true,
};

export const mockFolderPermissionDetails: FolderPermissionDetail[] = mockHubProjectFolders.map(({ id }) => ({
  id,
  type: contentTypes.FOLDERS,
  details: {
    create: true,
    upload: true,
  },
  permission: true,
}));

export const mockFolderPermission: FolderPermissionResult = {
  type: COMMAND_TYPE,
  id: 'permission-result',
  attributes: {
    status: 'complete',
    extension: {
      type: CHECK_PERMISSION_TYPE,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        requiredActions: [folderPermissionActions.CREATE, folderPermissionActions.UPLOAD],
        permissions: mockFolderPermissionDetails,
      },
    },
  },
  relationships: {
    resources: {
      data: [],
    },
  },
};

export const mockFolder1Permission: FolderPermissionResult = {
  type: COMMAND_TYPE,
  id: 'permission-result',
  attributes: {
    status: 'complete',
    extension: {
      type: CHECK_PERMISSION_TYPE,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        requiredActions: [folderPermissionActions.CREATE, folderPermissionActions.UPLOAD],
        permissions: [mockFolder1PermissionDetail],
      },
    },
  },
  relationships: {
    resources: {
      data: [],
    },
  },
};

export const mockFolder2Permission: FolderPermissionResult = {
  type: COMMAND_TYPE,
  id: 'permission-result',
  attributes: {
    status: 'complete',
    extension: {
      type: CHECK_PERMISSION_TYPE,
      version: '1.0',
      schema: {
        href: '',
      },
      data: {
        requiredActions: [folderPermissionActions.CREATE, folderPermissionActions.UPLOAD],
        permissions: [mockFolder2PermissionDetail],
      },
    },
  },
  relationships: {
    resources: {
      data: [],
    },
  },
};
