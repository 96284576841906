import App from 'App';
import ReviewPanel from 'components/OutputsPage/ReviewPanel/ReviewPanel';
import SettingsPanel from 'components/OutputsPage/SettingsPanel/SettingsPanel';
import InstancesPanel from 'components/ProductsPage/InstancesPanel/InstancesPanel';
import ModelsPanel from 'components/ProductsPage/ModelsPanel/ModelsPanel';
import InventorInstallersPage from 'pages/Installers/InventorInstallers';
import RevitInstallersPage from 'pages/Installers/RevitInstallers';
import LicensesPage from 'pages/Licencing/LicensingPage';
import { PageContainer } from 'pages/pages.styles';
import { FC, PropsWithChildren, useContext } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import AccessControlContext from './context/AccessControlStore/AccessControl.context';
import { AccessProps } from './context/AccessControlStore/accessControlStore';
import OutputsPage from './pages/OutputsPage/OutputsPage';
import ProductsPage from './pages/ProductsPage/ProductsPage';
import ReleasesPage from './pages/ReleasesPage/ReleasesPage';

type ProtectedRouteProps = {
  accessControlProperty: keyof AccessProps;
  redirectPath: string;
};
const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  accessControlProperty,
  redirectPath = '/',
}) => {
  const { initialized, accessProps } = useContext(AccessControlContext);

  if (!initialized) {
    return <>Loading</>;
  }

  if (accessProps[accessControlProperty]) {
    return children;
  }

  return <Navigate to={redirectPath} replace />;
};

export const routes = {
  index: {
    path: '/',
  },
  licensing: {
    path: '/licensing',
  },
  revitInstallers: {
    path: '/revit',
  },
  inventorInstallers: {
    path: '/inventor',
  },
  projectId: {
    path: '/projects/:projectId',
    id: 'projectId',
  },
  products: {
    path: 'products',
    id: 'productsId',
  },
  models: {
    path: 'models',
  },
  instances: {
    path: 'instances',
  },
  outputs: {
    path: 'outputs',
    id: 'outputsId',
  },
  review: {
    path: 'review',
  },
  settings: {
    path: 'settings',
  },
  releases: {
    path: 'releases',
  },
  folders: {
    path: 'folders/:folderUrn',
  },
  folderItems: {
    path: 'items/:itemUrn/:details?',
  },
};

export const nonProjectRoutes = [
  {
    path: routes.licensing.path,
    element: <LicensesPage />,
  },
  {
    path: routes.inventorInstallers.path,
    element: <InventorInstallersPage />,
  },
  {
    path: routes.revitInstallers.path,
    element: <RevitInstallersPage />,
  },
];

export const routerConfig = [
  {
    path: routes.index.path,
    element: <App />,
    children: nonProjectRoutes,
  },
  {
    path: routes.projectId.path,
    element: <App />,
    id: routes.projectId.id,
    children: [
      {
        path: routes.products.path,
        element: (
          <PageContainer>
            <ProductsPage />
          </PageContainer>
        ),
        id: routes.products.id,
        children: [
          {
            path: routes.models.path,
            element: <ModelsPanel />,
            children: [
              {
                path: routes.folders.path,
                element: <ModelsPanel />,
                children: [
                  {
                    path: routes.folderItems.path,
                    element: <ModelsPanel />,
                  },
                ],
              },
            ],
          },
          {
            path: routes.instances.path,
            element: <InstancesPanel />,
          },
        ],
      },
      {
        path: routes.outputs.path,
        element: (
          <PageContainer>
            <OutputsPage />
          </PageContainer>
        ),
        id: routes.outputs.id,
        children: [
          {
            path: routes.review.path,
            element: <ReviewPanel />,
          },
          {
            path: routes.settings.path,
            element: <SettingsPanel />,
          },
        ],
      },
      {
        path: routes.releases.path,
        element: (
          <ProtectedRoute accessControlProperty="releasesTab" redirectPath={routes.products.id}>
            <PageContainer>
              <ReleasesPage />
            </PageContainer>
          </ProtectedRoute>
        ),
      },
    ],
  },
  // handle the unknown routes, just navigate to the root (404 case)
  {
    path: '*',
    element: <Navigate to={routes.index.path} replace />,
  },
];

export const router = createBrowserRouter(routerConfig);
