import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Tree, TreeNode } from '../../../types/common';
import CreateNewFolder from './CreateNewFolder';
import { FolderNode, FolderNodeContainer } from './FoldersTree.styles';
import FolderIcon from './FolderIcon';

interface FoldersTreeProps {
  folderUrn?: string;
  foldersTree: Tree | undefined;
  expandedTreeNodeIds: string[];
  handleFolderToggle: (_event: React.SyntheticEvent, nodeIds: string[]) => Promise<void>;
  handleFolderClick: (folderUrn: string) => void;
  showNewFolderCreation?: boolean;
  folderCreationError?: string;
  folderCreationRequestLoading?: boolean;
  handleNewFolderNameConfirmation?: (folderName: string) => void;
  handleNewFolderNameCancellation?: () => void;
  resetSubFolderCreationError?: () => void;
  editPermissionLookup?: Record<string, boolean>;
}

export const FoldersTree: React.FC<FoldersTreeProps> = ({
  folderUrn,
  expandedTreeNodeIds,
  foldersTree,
  handleFolderClick,
  handleFolderToggle,
  showNewFolderCreation = false,
  folderCreationError,
  folderCreationRequestLoading,
  handleNewFolderNameConfirmation,
  handleNewFolderNameCancellation,
  resetSubFolderCreationError,
  editPermissionLookup,
}) => {
  const renderTree = (children: TreeNode[]) =>
    children.map((child) => {
      const { id, label } = child;
      const childrenNodes =
        foldersTree?.[id] && foldersTree[id].length > 0 ? renderTree(foldersTree[id]) : [<div key={id} />];

      const isCreatingASubFolder = showNewFolderCreation && id === folderUrn;
      const isFolderExpanded = expandedTreeNodeIds.some((nodeId: string) => nodeId === id) || isCreatingASubFolder;

      return (
        <TreeItem
          key={id}
          nodeId={id}
          label={
            <FolderNodeContainer data-id={id}>
              <FolderNode>
                <FolderIcon isExpanded={isFolderExpanded} folderUrn={id} editPermissionLookup={editPermissionLookup} />
              </FolderNode>
              <Typography variant="body2" noWrap display="flex" alignItems="center">
                {label}
              </Typography>
            </FolderNodeContainer>
          }
          onClick={() => handleFolderClick(id)}
        >
          {isCreatingASubFolder && handleNewFolderNameConfirmation && handleNewFolderNameCancellation && (
            <CreateNewFolder
              onNewFolderNameConfirmation={handleNewFolderNameConfirmation}
              onNewFolderNameCancellation={handleNewFolderNameCancellation}
              folderCreationError={folderCreationError}
              folderCreationRequestLoading={folderCreationRequestLoading}
              resetSubFolderCreationError={resetSubFolderCreationError}
            />
          )}
          {childrenNodes}
        </TreeItem>
      );
    });

  return (
    <TreeView
      selected={folderUrn}
      defaultCollapseIcon={<ArrowDropDown />}
      defaultExpandIcon={<ArrowRight />}
      onNodeToggle={handleFolderToggle}
      expanded={expandedTreeNodeIds}
    >
      {renderTree(foldersTree?.root || [])}
    </TreeView>
  );
};

export default FoldersTree;
