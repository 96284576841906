import { AboutInfoMenu, MidWebAppMoniker, darkTheme } from '@mid-react-common/common';
import { ThemeProvider } from '@mui/material/';
import AppBar from '@mui/material/AppBar';
import AccountProjectContext from 'context/AccountProjectStore/AccountProject.context';
import { useContext } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import logoMid from '../../../images/logo.png';
import { routes } from '../../../routes';
import { header } from '../../../tests/helpers/dataTestIds';
import ProjectSelectorPanel from '../ProjectSelectorPanel/ProjectSelectorPanel';
import UserSettingsPanel from '../UserSettingsPanel/UserSettingsPanel';
import { AppLogoContainer, AppLogoImage, HeaderAppBarContainer, HeaderToolbarContainer } from './HeaderAppBar.style';

function HeaderAppBar(): JSX.Element {
  // Go to back to Products if user clicks on the logo
  const { projectId } = useContext(AccountProjectContext);
  const getProjectRoute = () => {
    if (projectId) {
      return generatePath([routes.projectId.path].join('/'), {
        projectId,
      });
    }
    // No project yet selected
    return routes.index.path;
  };

  return (
    <HeaderAppBarContainer>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" elevation={0}>
          <HeaderToolbarContainer variant="dense">
            <AppLogoContainer>
              <NavLink to={getProjectRoute()} data-testid={header.logo}>
                <AppLogoImage src={logoMid} alt="" />
              </NavLink>
            </AppLogoContainer>
            <AboutInfoMenu webComponentVersion={import.meta.env.VITE_MIDW_VERSION || MidWebAppMoniker} />
            <ProjectSelectorPanel />
            <UserSettingsPanel />
          </HeaderToolbarContainer>
        </AppBar>
      </ThemeProvider>
    </HeaderAppBarContainer>
  );
}
export default HeaderAppBar;
