import { ENVIRONMENT, Environment, FormRules, FormRulesInput } from 'mid-types';
import { DCInput, DCInputBase, InputType, VariantInput } from '@adsk/offsite-dc-sdk';
import { isFormRulesGroup } from '../typeGuards';
import { uniqBy } from 'lodash';
import { logError } from '../errors/logging';

export const getReactAppEnvironment = (): Environment => {
  const env = import.meta.env.VITE_ENVIRONMENT as Environment;

  return env || ENVIRONMENT.MOCK;
};

export const removeDoubleQuotesFromStringArray = (array: string[]): string[] =>
  array.map((value) => removeDoubleQuotes(value));
export const removeDoubleQuotes = (value: string): string => value.replace(/^"(.*)"$/, '$1');
export const addDoubleQuotesToStringArray = (array: string[]): string[] => array.map((value) => addDoubleQuotes(value));
export const addDoubleQuotes = (value: string): string => (value.startsWith('"') ? value : `"${value}"`);
export const addQuotesToTextParametersObject = <Input extends DCInput>(
  parameters: Record<string, Input>,
): Record<string, Input> => {
  for (const [key, parameter] of Object.entries(parameters)) {
    if (parameter.type === InputType.TEXT) {
      const unTrimmedValue = addDoubleQuotes(parameter.value);
      const unTrimmedArray = parameter.values ? addDoubleQuotesToStringArray(parameter.values) : parameter.values;
      parameters[key] = { ...parameter, value: unTrimmedValue, values: unTrimmedArray };
    }
  }

  return parameters;
};

const removeQuotes = <Input extends DCInput | VariantInput>(parameter: Input): Input => {
  if (parameter.type === InputType.TEXT) {
    const trimmedValue = removeDoubleQuotes(parameter.value);
    if ('values' in parameter) {
      const trimmedArray = parameter.values ? removeDoubleQuotesFromStringArray(parameter.values) : parameter.values;
      return { ...parameter, value: trimmedValue, values: trimmedArray };
    }
    return { ...parameter, value: trimmedValue };
  }

  return parameter;
};

export const removeQuotesFromTextParametersInArray = <Input extends DCInput | VariantInput>(parameters: Input[]): Input[] =>
  parameters.map(removeQuotes);

export const applyFormRulesToInputs = <T extends DCInputBase>({
  formRules,
  productReleaseInputs,
}: {
  formRules: string | undefined;
  productReleaseInputs: T[];
}): T[] => {
  let formRulesObject: FormRules | undefined;

  try {
    if (formRules) {
      formRulesObject = JSON.parse(formRules);
    }
  } catch (error) {
    logError(error);
  }

  if (formRulesObject) {
    // Flatten the form rules object to get the inputs to display
    const flattenedInputs = formRulesObject.inputs.reduce<FormRulesInput[]>((acc, input) => {
      if (isFormRulesGroup(input)) {
        acc = acc.concat(input.inputs);
      } else {
        acc.push(input);
      }
      return acc;
    }, []);

    // merge the inputs from the form rules with the inputs from the product release
    const mergedInputsAndRules = flattenedInputs.reduce<T[]>((acc, formInputs) => {
      const productInput = productReleaseInputs.find((input) => input.name === formInputs.name);
      if (productInput) {
        acc.push({ ...productInput, ...formInputs });
      }
      return acc;
    }, []);

    // Return the "unique" merged inputs
    return uniqBy(mergedInputsAndRules, 'name');
  }

  // Otherwise, we just return the product release inputs without labels
  // NOTE: the only way to set labels is through in the API is by input rules.
  // The requirement is that we don't show the labels set by input rules, only by form rules.
  return productReleaseInputs.map((input) => (input.label ? { ...input, label: undefined } : input));
};

export const getDefaultInputLabel = (input: DCInput): string => {
  if (input.type === InputType.NUMERIC) {
    return `${input.name} (${input.unit})`;
  }
  return `${input.name}`;
};
