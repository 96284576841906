import { Vendors } from '@adsk/offsite-dc-sdk';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ProjectFolderPermissionContainer } from './ProjectFolderPermissionSection.styles';
import { AUTODESK_DOCS_PERMISSION_LINK, FUSION_PERMISSION_LINK } from 'global/constants/links';

import text from 'global/text.json';

const uploadLocationText = text.uploadLocation;

interface ProjectFolderPermissionSectionProps {
  summary: string;
  vendor: Vendors;
}

export const ProjectFolderPermissionSection: React.FC<ProjectFolderPermissionSectionProps> = ({ summary, vendor }) => (
  <ProjectFolderPermissionContainer>
    <Typography variant="body2">{summary}</Typography>
    <Link
      href={vendor === Vendors.FUSIONTEAM ? FUSION_PERMISSION_LINK : AUTODESK_DOCS_PERMISSION_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography variant="body2">
        {vendor === Vendors.FUSIONTEAM
          ? uploadLocationText.projectSecurityInFusion
          : uploadLocationText.folderPermissionsInAutodeskDocs}
      </Typography>
    </Link>
  </ProjectFolderPermissionContainer>
);
