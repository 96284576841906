import { ENVIRONMENT, Environment } from 'mid-types';

const MIDW_PORT = import.meta.env.MIDW_PORT || 8080;

export const MID_DOMAIN = 'informeddesign.autodesk.com';

const PRD_FORGE_API_URL = 'https://developer.api.autodesk.com';
const NON_PRD_FORGE_API_URL = 'https://developer-stg.api.autodesk.com';
const MOCK_FORGE_API_URL = NON_PRD_FORGE_API_URL;

const PRD_FORGE_ADMIN_API_URL = `${PRD_FORGE_API_URL}/construction/admin/v1`;
const NON_PRD_FORGE_ADMIN_API_URL = `${NON_PRD_FORGE_API_URL}/construction/admin/v1`;
const MOCK_FORGE_ADMIN_API_URL = NON_PRD_FORGE_ADMIN_API_URL;

const PRD_OFFSITE_API_URL = 'https://api.dc.autodesk.com/v1';
const STG_OFFSITE_API_URL = 'https://api.stg.dc.autodesk.com/v1';
const DEV_OFFSITE_API_URL = 'https://api.dev.dc.autodesk.com/v1';
const MOCK_OFFSITE_API_URL = DEV_OFFSITE_API_URL;

const PRD_MID_ARTIFACTS = `https://artifacts.${MID_DOMAIN}`;
const STG_MID_ARTIFACTS = `https://artifacts.stg.${MID_DOMAIN}`;
const DEV_MID_ARTIFACTS = `https://artifacts.dev.${MID_DOMAIN}`;
const MOCK_MID_ARTIFACTS = `http://localhost:${MIDW_PORT}/proxy/dev`;

const PRD_MID_WEBAPP = `https://${MID_DOMAIN}`;
const STG_MID_WEBAPP = `https://stg.${MID_DOMAIN}`;
const DEV_MID_WEBAPP = `https://dev.${MID_DOMAIN}`;
const MOCK_MID_WEBAPP = DEV_MID_WEBAPP;

const NON_PRD_PROFILE_SETTING_URL = 'https://accounts-staging.autodesk.com';
const PRD_PROFILE_SETTING_URL = 'https://accounts.autodesk.com';
const MOCK_PROFILE_SETTING_URL = NON_PRD_PROFILE_SETTING_URL;

const PRD_DOCUMENTS_MANAGER_URL = 'https://docs.b360.autodesk.com/projects';
const NON_PRD_DOCUMENTS_MANAGER_URL = 'https://docs.b360-staging.autodesk.com/projects';
const MOCK_DOCUMENTS_MANAGER_URL = NON_PRD_DOCUMENTS_MANAGER_URL;

const NON_PRD_DERIVATIVE_CDN_URL = 'https://cdn-stg.derivative.autodesk.com';
const PRD_DERIVATIVE_CDN_URL = 'https://cdn.derivative.autodesk.com';
const MOCK_DERIVATIVE_CDN_URL = NON_PRD_DERIVATIVE_CDN_URL;

const PRD_FUSION_TEAM_URL = 'autodesk360.com/g/projects';
const NON_PRD_FUSION_TEAM_URL = 'staging.autodesk360.com/g/projects';
const MOCK_FUSION_TEAM_URL = NON_PRD_FUSION_TEAM_URL;

export const STG_ADSK_IDP_ORIGIN = 'accounts-staging.autodesk.com';
export const PRD_ADSK_IDP_ORIGIN = 'accounts.autodesk.com';
export const MOCK_ADSK_IDP_ORIGIN = STG_ADSK_IDP_ORIGIN;

export enum ServiceTypes {
  FORGE_API = 'FORGE_API',
  FORGE_ADMIN_API = 'FORGE_ADMIN_API',
  OFFSITE_API = 'OFFSITE_API',
  MID_ARTIFACTS = 'MID_ARTIFACTS',
  MID_WEBAPP = 'MID_WEBAPP',
  PROFILE_SETTING_API = 'PROFILE_SETTING_API',
  DOCUMENTS_MANAGER_API = 'DOCUMENTS_MANAGER_API',
  DERIVATIVE_CDN = 'DERIVATIVE_CDN',
  FUSION_TEAM_WEBAPP = 'FUSION_TEAM_WEBAPP',
}

export const ServiceConfigMap: Record<keyof typeof ServiceTypes, Record<Environment, { api: string }>> = {
  [ServiceTypes.FORGE_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_FORGE_API_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_FORGE_API_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_FORGE_API_URL,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_FORGE_API_URL,
    },
  },
  [ServiceTypes.FORGE_ADMIN_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_FORGE_ADMIN_API_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_FORGE_ADMIN_API_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_FORGE_ADMIN_API_URL,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_FORGE_ADMIN_API_URL,
    },
  },
  [ServiceTypes.DERIVATIVE_CDN]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_DERIVATIVE_CDN_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_DERIVATIVE_CDN_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_DERIVATIVE_CDN_URL,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_DERIVATIVE_CDN_URL,
    },
  },
  [ServiceTypes.OFFSITE_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_OFFSITE_API_URL,
    },
    [ENVIRONMENT.STG]: {
      api: STG_OFFSITE_API_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: DEV_OFFSITE_API_URL,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_OFFSITE_API_URL,
    },
  },
  [ServiceTypes.MID_ARTIFACTS]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_MID_ARTIFACTS,
    },
    [ENVIRONMENT.STG]: {
      api: STG_MID_ARTIFACTS,
    },
    [ENVIRONMENT.DEV]: {
      api: DEV_MID_ARTIFACTS,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_MID_ARTIFACTS,
    },
  },
  [ServiceTypes.MID_WEBAPP]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_MID_WEBAPP,
    },
    [ENVIRONMENT.STG]: {
      api: STG_MID_WEBAPP,
    },
    [ENVIRONMENT.DEV]: {
      api: DEV_MID_WEBAPP,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_MID_WEBAPP,
    },
  },
  [ServiceTypes.PROFILE_SETTING_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_PROFILE_SETTING_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_PROFILE_SETTING_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_PROFILE_SETTING_URL,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_PROFILE_SETTING_URL,
    },
  },
  [ServiceTypes.DOCUMENTS_MANAGER_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_DOCUMENTS_MANAGER_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_DOCUMENTS_MANAGER_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_DOCUMENTS_MANAGER_URL,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_DOCUMENTS_MANAGER_URL,
    },
  },
  [ServiceTypes.FUSION_TEAM_WEBAPP]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_FUSION_TEAM_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_FUSION_TEAM_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_FUSION_TEAM_URL,
    },
    [ENVIRONMENT.MOCK]: {
      api: MOCK_FUSION_TEAM_URL,
    },
  },
};
