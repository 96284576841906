import { PostVariantOutput, ProductRelease, PutProductUserConfigurationPayload, Vendors } from '@adsk/offsite-dc-sdk';
import { uniq } from 'lodash';
import { getDcApiServiceInstance } from 'mid-api-services';
import { OutputTypeWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';
import text from '../../../global/text.json';
import { Instance } from '../../../types/product';

const productsText = text.productsStore;
const outputsText = text.useGenerateOutputs;

export type VariantIdProductMap = { [key: string]: ProductRelease };

export enum GeneratingState {
  NONE,
  CHECKING_BALANCE,
  GENERATING,
}

const getCurrentProductRelease = (productId: string, productRelease: number, products: ProductRelease[]): ProductRelease => {
  const currentProductRelease = products.find(
    (product) => product.contentId === productId && product.release === productRelease,
  );
  if (!currentProductRelease) {
    throw new Error(productsText.failedToFetchAssociateProducts);
  }
  return currentProductRelease;
};

export const getVariantIdProductMap = (
  selectedVariantInstances: Instance[],
  products: ProductRelease[],
  selectedProductId: string,
): VariantIdProductMap =>
  selectedVariantInstances.reduce<VariantIdProductMap>((prev, current) => {
    if (!prev[current.variantId] && current.contentId === selectedProductId) {
      prev[current.variantId] = getCurrentProductRelease(selectedProductId, current.release, products);
    }
    return prev;
  }, {});

export const calculateCost = (
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
  selectedInstances: Instance[] | undefined,
  productReleases: ProductRelease[],
  selectedProductId: string,
): number => {
  const variantIdProductMap = selectedInstances
    ? getVariantIdProductMap(selectedInstances, productReleases, selectedProductId)
    : {};

  // TOTAL_OUTPUTS_COUNT = VARIANTS_COUNT X SELECTED_OUTPUTS_COUNT
  return Object.keys(variantIdProductMap).length * selectedOutputs.length;
};

export const getButtonTextByGeneratingState = (generatingState: GeneratingState): string => {
  switch (generatingState) {
    case GeneratingState.GENERATING: {
      return outputsText.generatingButton;
    }
    case GeneratingState.CHECKING_BALANCE: {
      return outputsText.checkingBalanceButton;
    }
    default: {
      return outputsText.generateButton;
    }
  }
};

type SaveUserConfigProps = {
  selectedOutputs: PostVariantOutputWithVirtualTypes[];
  uploadFolderUrn: string;
  accountId: string;
  projectId: string;
  selectedProjectId: string;
  selectedProductId: string;
  vendor: Vendors;
};

export const updateProductUserConfiguration = async ({
  selectedOutputs,
  uploadFolderUrn,
  accountId,
  projectId,
  selectedProjectId,
  selectedProductId,
  vendor,
}: SaveUserConfigProps): Promise<void> => {
  const outputTypes = uniq(
    selectedOutputs
      // filter out the virtual output types
      .filter<PostVariantOutput>(
        (selectedOutput): selectedOutput is PostVariantOutput =>
          selectedOutput.type !== OutputTypeWithVirtualTypes.BOMAGGREGATED,
      )
      .map((output) => output.type),
  );

  const productUserConfiguration = outputTypes.reduce<PutProductUserConfigurationPayload>(
    (payload, outputType) => ({
      outputLocations: {
        ...payload.outputLocations,
        [outputType]: {
          vendor,
          accountId,
          projectId,
          outputLocation: uploadFolderUrn,
        },
      },
    }),
    { outputLocations: {} },
  );

  await getDcApiServiceInstance().updateProductUserConfiguration(
    selectedProjectId,
    selectedProductId,
    productUserConfiguration,
  );
};
