import { NotificationContext, StateSetter, useCancellablePromise } from '@mid-react-common/common';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { BIM360Document } from 'mid-types';
import { useContext, useState } from 'react';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import { FolderContentRow } from '../ModelsFolderContent/ModelsFolderContent.types';
import useMaintainRouting from './useMaintainRouting';

type UseModelPanelState = {
  folderUrn: string;
  lmvModelFile: BIM360Document[] | null;
  selectedModelFolder: FolderContentRow | null;
  isPreviewLoading: boolean;
  setIsPreviewLoading: StateSetter<boolean>;
  setSelectedModelFolder: StateSetter<FolderContentRow | null>;
  removeDetailsSegmentFromRoute: () => void;
  handleFolderClick: (folderUrn: string) => void;
  selectFolder: (folderUrn: string) => Promise<void>;
};

const useModelsPanel = (): UseModelPanelState => {
  const { projectId } = useContext(AccountProjectContext);
  const {
    selectedModelFolder,
    lmvModelFile,
    setSelectedFolderUrn,
    setSelectedModelFolder,
    setLmvModelFile,
    selectedFolderUrn,
  } = useContext(ModelSelectionContext);

  const { logAndShowNotification } = useContext(NotificationContext);

  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);

  const cancellablePromise = useCancellablePromise();

  const { folderUrnFromURL, removeDetailsSegmentFromRoute, setRouteToFolder } = useMaintainRouting();

  const selectFolder = async (folderUrn: string) => {
    if (!projectId) {
      return;
    }

    try {
      const folderContentResult = await cancellablePromise(
        getForgeApiServiceInstance().getFolderContent(projectId, folderUrn),
      );
      setLmvModelFile(folderContentResult);

      setSelectedFolderUrn(folderUrn);
    } catch (error) {
      logAndShowNotification({ error });
    }
  };

  const handleFolderClick = (folderUrn: string) => {
    selectFolder(folderUrn);
    setRouteToFolder(folderUrn);
  };

  return {
    folderUrn: folderUrnFromURL || selectedFolderUrn,
    lmvModelFile,
    selectedModelFolder,
    isPreviewLoading,
    setIsPreviewLoading,
    setSelectedModelFolder,
    removeDetailsSegmentFromRoute,
    selectFolder,
    handleFolderClick,
  };
};

export default useModelsPanel;
