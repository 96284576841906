import { NavItem, Sidebar } from 'pages/Sidebar';
import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useDefaultNavigation } from '../../global/hooks/hooks';
import text from '../../global/text.json';
import JobSettingsIcon from '../../images/job-settings.svg?react';
import ReviewResultsIcon from '../../images/review results.svg?react';
import { routes } from '../../routes';
import useOpenModelURLHandling from './useOpenModelURLHandling';

const OutputsPage = (): JSX.Element => {
  const lmvContainer = useRef<HTMLDivElement>(null);
  useDefaultNavigation(routes.outputs.id, routes.review.path);

  const navItems: NavItem[] = [
    { name: text.outputsSubNavs.review, path: routes.review.path, icon: <ReviewResultsIcon /> },
    {
      name: text.outputsSubNavs.settings,
      path: routes.settings.path,
      icon: <JobSettingsIcon />,
    },
  ];

  useOpenModelURLHandling(lmvContainer);
  return (
    <>
      <Sidebar navItems={navItems} />
      <Outlet />
      {/* Container for the LMV to be rendered. This LMV is needed just to get some data form the model to render outputs.
      It should be invisible for the user. */}
      <div ref={lmvContainer} style={{ display: 'none' }} />
    </>
  );
};

export default OutputsPage;
