import { Vendors } from '@adsk/offsite-dc-sdk';
import { useQueries, useQuery } from '@tanstack/react-query';
import { escapeRegExp } from 'lodash';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { hubTypes } from 'mid-types';
import { useCallback, useMemo, useState } from 'react';
import { HUB_KEY, HUB_PROJECT_KEY, PROJECT_ENTITLEMENT_KEY } from '../../../../../../global/constants/reactQueryKeys';
import { UploadLocationProject } from '../../../../../../types/uploadLocationProject';
import { vendorPlatforms } from 'global/constants/bim360';

interface UseOutputProjectSelectorState {
  projects: UploadLocationProject[];
  anchorElPopover: null | HTMLElement;
  loading: boolean;
  handleProjectMenuOpen: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  handleProjectMenuClose: () => void;
  handleSearchProjects: (value: string) => void;
}

const sortOutputProjects = (a: UploadLocationProject, b: UploadLocationProject): number => {
  if (a.name < b.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }

  if (a.accountName < b.accountName) {
    return -1;
  }

  return 1;
};

export const useOutputProjectSelector = (): UseOutputProjectSelectorState => {
  const [anchorElPopover, setAnchorElPopover] = useState<null | HTMLElement>(null);
  const [filteredProjects, setFilteredProjects] = useState<UploadLocationProject[]>([]);

  const forgeApiService = getForgeApiServiceInstance();

  const hubListResult = useQuery({
    queryKey: [HUB_KEY],
    queryFn: ({ signal }) => forgeApiService.getHubs(signal),
  });

  const fusionProjectListQueries =
    hubListResult.data
      ?.filter((hub) => hub.attributes.extension.type === hubTypes.FUSION)
      .map(
        ({
          id: hubId,
          attributes: {
            name: accountName,
            extension: { type: hubType },
          },
        }) => ({
          queryKey: [HUB_PROJECT_KEY, hubId],
          queryFn: async ({ signal }: { signal: AbortSignal }) =>
            (await forgeApiService.getHubProjects({ hubId, hubType, signal })).map((project) => ({
              id: project.id,
              name: project.attributes.name,
              accountId: hubId,
              accountName,
              vendor: Vendors.FUSIONTEAM,
            })),
          enabled: !!hubListResult.data,
        }),
      ) || [];

  const { data: fusionProjects, isPending: isFusionProjectListPending } = useQueries({
    queries: fusionProjectListQueries,
    combine: (results) => ({
      data: results.reduce<UploadLocationProject[]>(
        (projects, currentProjectList) => (currentProjectList.data ? [...projects, ...currentProjectList.data] : projects),
        [],
      ),
      isPending: results.some((result) => result.isPending),
    }),
  });

  const bim360AndAccProjectListResult = useQuery({
    queryKey: [PROJECT_ENTITLEMENT_KEY],
    queryFn: ({ signal }) => forgeApiService.getProjectEntitlements(signal),
  });

  const allProjects = useMemo(() => {
    const bim360AndAccProjects: UploadLocationProject[] =
      bim360AndAccProjectListResult.data?.map(({ id, name, accountId, accountName, platform }) => ({
        id,
        name,
        accountId,
        accountName,
        vendor: vendorPlatforms[platform],
      })) || [];

    return [...bim360AndAccProjects, ...fusionProjects].sort(sortOutputProjects);
  }, [fusionProjects, bim360AndAccProjectListResult.data]);

  const handleProjectMenuClose = () => {
    setAnchorElPopover(null);
  };

  const handleProjectMenuOpen = async (event: React.MouseEvent<HTMLElement>) => {
    // Clear up previous project search results
    setFilteredProjects(allProjects);
    setAnchorElPopover(event.currentTarget);
  };

  const handleSearchProjects = useCallback(
    (value: string): void => {
      const regex = new RegExp(escapeRegExp(value), 'i');

      const filteredProjectList = allProjects.filter((project: UploadLocationProject) => {
        const titleLowercase = project.name.toLowerCase();
        return regex.test(titleLowercase);
      });

      setFilteredProjects(filteredProjectList);
    },
    [allProjects, setFilteredProjects],
  );

  return {
    projects: filteredProjects,
    anchorElPopover,
    loading: hubListResult.isPending || isFusionProjectListPending || bim360AndAccProjectListResult.isPending,
    handleProjectMenuOpen,
    handleProjectMenuClose,
    handleSearchProjects,
  };
};
