import { getDcApiServiceInstance } from 'mid-api-services';
import { useState, useContext } from 'react';
import { ProductRelease } from '@adsk/offsite-dc-sdk';
import text from '../../../global/text.json';
import { NOTIFICATION_STATUSES, NotificationContext } from '@mid-react-common/common';

interface UseReleaseNotesEditState {
  dialogOpen: boolean;
  isNotesUpdating: boolean;
  notesValid: boolean;
  notes: string;
  handleDialogClose: () => void;
  handleDialogOpen: () => void;
  handleNotesSave: () => Promise<void>;
  handleNotesChange: (value: string, isValid: boolean) => void;
}

interface UseReleaseNotesEditProps {
  currentRelease: ProductRelease;
  setReloadReleaseData: (reload: boolean) => void;
}

const useReleaseNotesEdit = ({
  currentRelease,
  setReloadReleaseData,
}: UseReleaseNotesEditProps): UseReleaseNotesEditState => {
  const { logAndShowNotification, showNotification } = useContext(NotificationContext);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [notesValid, setNotesValid] = useState<boolean>(true);
  const [isNotesUpdating, setIsNotesUpdating] = useState<boolean>(false);

  const handleDialogOpen = () => setDialogOpen(true);

  const handleDialogClose = () => setDialogOpen(false);

  const handleNotesChange = (value: string, isValid: boolean) => {
    setNotes(value);
    setNotesValid(isValid);
  };

  const handleNotesSave = async () => {
    const dcApiService = getDcApiServiceInstance();

    try {
      setIsNotesUpdating(true);

      await dcApiService.updateProductReleaseNotes(
        currentRelease.tenancyId,
        currentRelease.contentId,
        currentRelease.release,
        notes,
      );

      showNotification({
        message: text.releases.successEditReleaseNotes,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (error) {
      logAndShowNotification({
        message: text.releases.failedToEditReleaseNotes,
        error,
      });
    } finally {
      setIsNotesUpdating(false);
      handleDialogClose();
      setReloadReleaseData(true);
    }
  };

  return {
    dialogOpen,
    notes,
    isNotesUpdating,
    notesValid,
    handleDialogOpen,
    handleDialogClose,
    handleNotesChange,
    handleNotesSave,
  };
};

export default useReleaseNotesEdit;
