import { Vendors } from '@adsk/offsite-dc-sdk';
import { useTheme } from '@mui/material/styles';
import { uploadLocationProjectSelectorTestIds } from 'tests/helpers/dataTestIds';
import AccIcon from '../../../../images/projectIcons/acc.svg?react';
import Bim360Logo from '../../../../images/projectIcons/bim360.png';
import FusionLogo from '../../../../images/projectIcons/fusion.png';

interface ProjectIconProps {
  vendor: Vendors;
}

export const ProjectIcon: React.FC<ProjectIconProps> = ({ vendor }) => {
  const theme = useTheme();

  const projectIconMap: Record<Vendors, JSX.Element> = {
    [Vendors.ACC]: <AccIcon data-testid={uploadLocationProjectSelectorTestIds.accProjectIconType} />,
    [Vendors.BIM360]: (
      <img
        src={Bim360Logo}
        height={theme.var.projectIconSize}
        data-testid={uploadLocationProjectSelectorTestIds.bim360ProjectIconType}
      />
    ),
    [Vendors.FUSIONTEAM]: (
      <img
        src={FusionLogo}
        height={theme.var.projectIconSize}
        data-testid={uploadLocationProjectSelectorTestIds.fusionProjectIconType}
      />
    ),
  };

  return projectIconMap[vendor];
};
