import { StateSetter } from '@mid-react-common/common';
import { AccProject, BIMAccount } from 'mid-types';
import { useState } from 'react';
import { generatePath, matchPath, useMatch, useNavigate } from 'react-router-dom';
import { nonProjectRoutes, routes } from 'routes';
import { usePrevious } from '../../global/hooks/hooks';
import { BIM360LocalStorageKeys } from 'global/constants/bim360';

interface ClearProjectDataArgs {
  updateLocalStorage?: boolean;
}
export interface AccountProjectsStore {
  accountId: string | null;
  projectId: string | null;
  projectName: string | null;
  accountDisplayName: string | undefined;
  accountImageURL: string | undefined;
  accountIdInCurrentProject: string | undefined;
  previousProjectId: string | undefined;
  hasProjectChanged: boolean;
  currentAccount: BIMAccount | null;
  currentProject: AccProject | null;
  setCurrentAccount: StateSetter<BIMAccount | null>;
  setCurrentProject: (project: AccProject | null, updateLocalStorage?: boolean) => void;
  clearAccountData: () => void;
  clearProjectData: (clearProjectDataArgs: ClearProjectDataArgs) => void;
}

export const useAccountProjectsStore = (): AccountProjectsStore => {
  const [currentAccount, setCurrentAccount] = useState<BIMAccount | null>(null);
  const [currentProject, _setCurrentProject] = useState<AccProject | null>(null);

  const previousProjectId = usePrevious(currentProject?.id);
  const hasProjectChanged = !!previousProjectId && previousProjectId !== currentProject?.id;
  const navigate = useNavigate();
  const isOnProjectIdRoute = !!useMatch(routes.projectId.path + '/*');
  const isNonProjectRoute = nonProjectRoutes.some((route) => matchPath(location.pathname, route.path));

  const setCurrentProject = (project: AccProject | null, updateLocalStorage = true) => {
    if (project && updateLocalStorage) {
      window.localStorage.setItem(BIM360LocalStorageKeys.SELECTED_PROJECT_ID, project.id);
    }
    if (!project && updateLocalStorage) {
      window.localStorage.removeItem(BIM360LocalStorageKeys.SELECTED_PROJECT_ID);
    }

    if (project) {
      const projectIdPath = generatePath(routes.projectId.path, { projectId: project.id });
      if (!isOnProjectIdRoute && !isNonProjectRoute) {
        navigate(projectIdPath);
      }
    }

    if (!project && !isNonProjectRoute) {
      navigate(routes.index.path);
    }
    _setCurrentProject(project);
  };

  const clearProjectData = (localStorageArgs: ClearProjectDataArgs) => {
    setCurrentProject(null, localStorageArgs.updateLocalStorage);
  };

  const clearAccountData = () => {
    setCurrentAccount(null);
  };

  return {
    accountId: currentAccount?.id || null,
    accountDisplayName: currentAccount?.name,
    accountImageURL: currentAccount?.thumbnailImageUrl,
    currentAccount,
    projectId: currentProject?.id || null,
    // The accountIdInCurrentProject & accountId can be different,
    // as they come from two different sources (error scenario)
    accountIdInCurrentProject: currentProject?.accountId,
    projectName: currentProject?.name || null,
    previousProjectId,
    hasProjectChanged,
    currentProject,
    setCurrentProject,
    setCurrentAccount,
    clearAccountData,
    clearProjectData,
  };
};
