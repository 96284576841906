import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import UploadLocationContext from '../../../../context/UploadLocationStore/UploadLocation.context';
import text from '../../../../global/text.json';
import { UploadLocationIds } from '../../../../tests/helpers/dataTestIds';
import OutputFolderButtonAndModal from './OutputFolderButtonAndModal/OutputFolderButtonAndModal';
import {
  BreadcrumbsContainer,
  BreadcrumbsIconWrapper,
  BreadcrumbsItem,
  BreadcrumbsWrapper,
  LocationSelection,
  UploadLocationWrapper,
} from './UploadLocation.styles';
import HubIcon from '../../../../images/projectIcons/hub.svg?react';
import { ProjectIcon } from './ProjectIcon';

const uploadLocationText = text.uploadLocation;

const NUMBER_OF_ITEMS_FOR_ACCOUNT_PROJECT_AND_ROOT_FOLDER = 3;
const NUMBER_OF_ITEMS_FOR_DESTINATION_FOLDER = 1;
const MAX_BREADCRUMBS_ITEMS = 6;

export const UploadLocation: React.FC = () => {
  const theme = useTheme();

  const { uploadLocationBreadcrumbs, uploadLocationProject, uploadFolderUrn } = useContext(UploadLocationContext);

  // Once the breadcrumbs are exceeding number of max items, it would show ellipsis. When clicking on ellipsis, breadcrumbs
  // will be in expanded state. Breadcrumbs component maintains its own state to indicate if the breadcrumbs are been
  // expanded or not; when changing the folder urn, the breadcrumbs will always show breadcrumbs in expanded state if
  // breadcrumbs has been expanded previously.
  // By adding the key to Breadcrumb component, the breadcrumbs component will remount every time the folder is changed.
  // It will always show ellipsis when the new folder selected is exceeding number of max items in breadcrumbs.
  const resetBreadcrumbEllipsisStateKey = uploadFolderUrn;

  return (
    <UploadLocationWrapper>
      <Typography variant="h2" gutterBottom>
        {uploadLocationText.uploadLocation}
      </Typography>
      <LocationSelection>
        <BreadcrumbsWrapper data-testid={UploadLocationIds.breadcrumpsContent} className="mid-bg-shadow">
          {uploadLocationBreadcrumbs.length && uploadLocationProject ? (
            <>
              <BreadcrumbsContainer
                itemsBeforeCollapse={NUMBER_OF_ITEMS_FOR_ACCOUNT_PROJECT_AND_ROOT_FOLDER}
                itemsAfterCollapse={NUMBER_OF_ITEMS_FOR_DESTINATION_FOLDER}
                maxItems={MAX_BREADCRUMBS_ITEMS}
                key={resetBreadcrumbEllipsisStateKey}
              >
                <BreadcrumbsItem>
                  <BreadcrumbsIconWrapper>
                    <HubIcon height={theme.var.projectIconSize} width={theme.var.projectIconSize} />
                  </BreadcrumbsIconWrapper>
                  <Typography variant="body1">{uploadLocationProject.accountName}</Typography>
                </BreadcrumbsItem>
                <BreadcrumbsItem>
                  <BreadcrumbsIconWrapper>
                    <ProjectIcon vendor={uploadLocationProject.vendor} />
                  </BreadcrumbsIconWrapper>
                  <Typography variant="body1">{uploadLocationProject.name}</Typography>
                </BreadcrumbsItem>
                {uploadLocationBreadcrumbs.map((folder, index) => (
                  <BreadcrumbsItem key={folder.id}>
                    <BreadcrumbsIconWrapper>
                      <FolderOutlinedIcon fontSize="small" />
                    </BreadcrumbsIconWrapper>
                    <Typography variant="body1">
                      {index === uploadLocationBreadcrumbs.length - 1 ? <b>{folder.label}</b> : folder.label}
                    </Typography>
                  </BreadcrumbsItem>
                ))}
              </BreadcrumbsContainer>
            </>
          ) : (
            <Typography variant="body1">{uploadLocationText.notSpecified}</Typography>
          )}
        </BreadcrumbsWrapper>
        <OutputFolderButtonAndModal />
      </LocationSelection>
    </UploadLocationWrapper>
  );
};
