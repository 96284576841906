import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FoldersTree } from 'components/Commons/FoldersTree/FoldersTree';
import useFoldersTree from 'components/Commons/FoldersTree/useFoldersTree';
import AccountProjectContext from 'context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from 'context/ModelSelectionStore/ModelSelection.context';
import React, { useContext } from 'react';
import text from '../../../global/text.json';
import { FolderContainer, ProjectFoldersTreeWrapper, TitleContainer } from './ModelsFolderBrowser.style';
import useModelFolderBrowser from './useModelFolderBrowser';

interface ModelsFolderBrowserProps {
  handleFolderClick: (folderUrn: string) => void;
  selectFolder: (folderUrn: string) => void;
  folderUrn?: string;
}

const modelsFolderText = text.modelsFolderBrowser;

const ModelsFolderBrowser: React.FC<ModelsFolderBrowserProps> = ({ handleFolderClick, selectFolder, folderUrn }) => {
  const {
    projectFoldersTree,
    setProjectFoldersTree,
    expandedProjectFoldersTreeNodeIds,
    setExpandedProjectFoldersTreeNodeIds,
  } = useContext(ModelSelectionContext);
  const { projectId, accountId } = useContext(AccountProjectContext);
  const { handleFolderToggle, error } = useFoldersTree({
    accountId,
    projectId,
    foldersTree: projectFoldersTree,
    setFoldersTree: setProjectFoldersTree,
    setExpandedTreeNodeIds: setExpandedProjectFoldersTreeNodeIds,
    folderUrn,
    selectFolder,
  });
  useModelFolderBrowser({ error });

  return (
    <FolderContainer>
      <TitleContainer>
        <Typography variant="body1" noWrap>
          {modelsFolderText.selectModel}
        </Typography>
      </TitleContainer>
      <Divider />
      <ProjectFoldersTreeWrapper>
        <FoldersTree
          folderUrn={folderUrn}
          foldersTree={projectFoldersTree}
          expandedTreeNodeIds={expandedProjectFoldersTreeNodeIds}
          handleFolderToggle={handleFolderToggle}
          handleFolderClick={handleFolderClick}
        />
      </ProjectFoldersTreeWrapper>
    </FolderContainer>
  );
};

export default ModelsFolderBrowser;
