import { OutputItem } from './SettingsPanel';
import text from 'global/text.json';
import { OutputWithTreeTemplateLabels } from './OutputSettingsWithTree';
import {
  getDCOutputDrawingPath,
  getDCOutputModelStates,
  getVariantOutputModelState,
  getVariantOutputsDrawingPath,
  isModelStateBasedOutput,
  isOutputValid,
} from 'mid-utils';
import { DCOutput, OutputType } from '@adsk/offsite-dc-sdk';
import {
  DCOutputWithVirtualTypes,
  OutputTypesWithVirtualTypes,
  OutputTypeWithVirtualTypes,
  PostVariantOutputWithVirtualTypes,
} from 'mid-types';
import { isDrawingTemplateBasedOutput } from 'mid-utils';

const settingsPanelText = text.settingsPanel;

export const drawingsOutputTypesList: OutputTypesWithVirtualTypes[] = [OutputType.PDF, OutputType.DWG, OutputType.IDW];
export const neutralOutputTypesList: OutputTypesWithVirtualTypes[] = [
  OutputType.SAT,
  OutputType.STEP,
  OutputType.STL,
  OutputType.GLB,
];

export const removeExtension = (fileName: string): string => fileName.replace(/\.[^/.]+$/, '');
export const removePath = (filepath: string): string | undefined => filepath.split('\\').pop();

export const generateModelStateInfo = (
  output: DCOutputWithVirtualTypes,
  selectedOutput: PostVariantOutputWithVirtualTypes[],
): OutputItem | null => {
  const modelState = getDCOutputModelStates(output)?.[0];
  const outputType = output.type;

  switch (outputType) {
    case OutputType.IAM:
      return generateModelStateInfoForIAM(output, selectedOutput);
    case OutputTypeWithVirtualTypes.BOMAGGREGATED:
    case OutputType.BOM:
      return generateModelStateInfoForBOM(outputType, modelState, selectedOutput);
    case OutputType.STEP:
    case OutputType.SAT:
    case OutputType.GLB:
    case OutputType.STL:
      return generateModelStateInfoForNeutralFormat(outputType, modelState, selectedOutput);
    case OutputType.PDF:
    case OutputType.DWG:
    case OutputType.IDW:
      return generateModelStateInfoForDrawing(output, selectedOutput);

    default:
      return null;
  }
};

export const generateModelStateInfoForIAM = (
  output: DCOutput,
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
): OutputItem => ({
  outputLabel: output.type,
  chips: [`.${output.type.toLocaleLowerCase()}`],
  checked: selectedOutputs.some((output) => output.type === OutputType.IAM),
  outputType: output.type,
});

export const generateModelStateInfoForBOM = (
  outputType: OutputTypesWithVirtualTypes,
  modelState: string | undefined,
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
  isDisplayedInTree = false,
): OutputItem => {
  if (!modelState) {
    throw new Error(settingsPanelText.noModelStateAvailable);
  }

  let outputLabel = text.settingsPanel.instance;

  if (outputType === OutputTypeWithVirtualTypes.BOMAGGREGATED) {
    outputLabel = text.settingsPanel.aggregate;
  }

  return {
    outputLabel: isDisplayedInTree ? outputLabel : `${outputLabel} ${modelState}`,
    chips: [`.${settingsPanelText.csv}`],
    checked: selectedOutputs.some(
      (output) => getVariantOutputModelState(output) === modelState && output.type === outputType,
    ),
    outputType,
    modelState,
  };
};

const getNeutralFormatLabelPrefix = (outputType: OutputTypesWithVirtualTypes): string => {
  const neutralOutputVersion =
    outputType === OutputType.STEP
      ? settingsPanelText.neutralFormatStepVersion
      : outputType === OutputType.SAT
      ? settingsPanelText.neutralFormatSATVersion
      : null;
  return neutralOutputVersion ? `${outputType} ${neutralOutputVersion}` : outputType;
};

export const generateModelStateInfoForNeutralFormat = (
  outputType: OutputTypesWithVirtualTypes,
  modelState: string | undefined,
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
  isDisplayedInTree = false,
): OutputItem => {
  if (!modelState) {
    throw new Error(settingsPanelText.noModelStateAvailable);
  }
  return {
    outputType,
    outputLabel: isDisplayedInTree
      ? getNeutralFormatLabelPrefix(outputType)
      : `${getNeutralFormatLabelPrefix(outputType)} ${modelState}`,
    chips: [`.${outputType.toLocaleLowerCase()}`],
    checked: selectedOutputs.some(
      (selectedOutput) => getVariantOutputModelState(selectedOutput) === modelState && outputType === selectedOutput.type,
    ),
    modelState,
  };
};

export const generateModelStateInfoForDrawing = (
  output: DCOutputWithVirtualTypes,
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
): OutputItem => {
  const drawingPath = getDCOutputDrawingPath(output);
  if (!drawingPath) {
    throw new Error(settingsPanelText.noDrawingPathAvailable);
  }
  return {
    outputLabel: output.type === OutputType.PDF ? settingsPanelText.neutral : settingsPanelText.native,
    chips: [`.${output.type.toLowerCase()}`],
    checked: selectedOutputs.some(
      (selectedOutput) =>
        getVariantOutputsDrawingPath(selectedOutput) === drawingPath && selectedOutput.type === output.type,
    ),
    outputType: output.type,
    drawingTemplatePath: drawingPath,
  };
};

export const generateModelStateInfoTree = (
  outputs: DCOutputWithVirtualTypes[],
  selectedOutput: PostVariantOutputWithVirtualTypes[],
): OutputWithTreeTemplateLabels | null => {
  const outputType = outputs[0].type;

  switch (outputType) {
    case OutputType.BOM:
      return generateModelStateInfoForBOMList(outputs, selectedOutput);
    case OutputType.STEP:
    case OutputType.SAT:
    case OutputType.GLB:
    case OutputType.STL:
      return generateModelStateInfoForNeutralFormatList(outputs, selectedOutput);
    case OutputType.PDF:
    case OutputType.DWG:
    case OutputType.IDW:
      return generateModelStateInfoForDrawingList(outputs, selectedOutput);
    default:
      return null;
  }
};

export const generateModelStateInfoForBOMList = (
  outputs: DCOutputWithVirtualTypes[],
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
): OutputWithTreeTemplateLabels =>
  outputs.reduce<OutputWithTreeTemplateLabels>((acc, output) => {
    const modelStates = getDCOutputModelStates(output);
    if (modelStates?.length) {
      modelStates.forEach((modelState) => {
        const currentParentTreeLabel = acc[modelState];

        const modelStateInfo = generateModelStateInfoForBOM(output.type, modelState, selectedOutputs, true);

        if (currentParentTreeLabel) {
          currentParentTreeLabel.push(modelStateInfo);
        } else {
          acc[modelState] = [modelStateInfo];
        }
      });
    }
    return acc;
  }, {});

export const generateModelStateInfoForNeutralFormatList = (
  outputs: DCOutputWithVirtualTypes[],
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
): OutputWithTreeTemplateLabels =>
  outputs.reduce<OutputWithTreeTemplateLabels>((acc, output) => {
    const modelStates = getDCOutputModelStates(output);
    if (modelStates?.length) {
      modelStates.forEach((modelState) => {
        const currentParentTreeLabel = acc[modelState];
        if (currentParentTreeLabel) {
          currentParentTreeLabel.push(
            generateModelStateInfoForNeutralFormat(output.type, modelState, selectedOutputs, true),
          );
        } else {
          acc[modelState] = [generateModelStateInfoForNeutralFormat(output.type, modelState, selectedOutputs, true)];
        }
      });
    }

    return acc;
  }, {});

export const generateModelStateInfoForDrawingList = (
  outputs: DCOutputWithVirtualTypes[],
  selectedOutputs: PostVariantOutputWithVirtualTypes[],
): OutputWithTreeTemplateLabels =>
  outputs.reduce<OutputWithTreeTemplateLabels>((acc, output) => {
    const outputLabel = getDCOutputDrawingPath(output);

    if (outputLabel && isOutputValid(output.type)) {
      const currentLabel = acc[outputLabel];
      if (currentLabel) {
        currentLabel.push(generateModelStateInfoForDrawing(output, selectedOutputs));
      } else {
        acc[outputLabel] = [generateModelStateInfoForDrawing(output, selectedOutputs)];
      }
    }

    return acc;
  }, {});

export const transformOutputItemToPostVariantOutput = (
  outputLabelItem: OutputItem,
  drawingPath?: string,
): PostVariantOutputWithVirtualTypes | null => {
  const { outputType, modelState } = outputLabelItem;

  if (OutputTypeWithVirtualTypes.IAM === outputType) {
    return {
      type: outputType,
    };
  }
  if (
    (OutputTypeWithVirtualTypes.BOM === outputType ||
      OutputTypeWithVirtualTypes.BOMAGGREGATED === outputType ||
      OutputTypeWithVirtualTypes.STEP === outputType ||
      OutputTypeWithVirtualTypes.SAT === outputType ||
      OutputTypeWithVirtualTypes.GLB === outputType ||
      OutputTypeWithVirtualTypes.STL === outputType) &&
    modelState
  ) {
    return {
      type: outputType,
      modelState,
    };
  }

  if (
    (OutputTypeWithVirtualTypes.IDW === outputType ||
      OutputTypeWithVirtualTypes.DWG === outputType ||
      OutputTypeWithVirtualTypes.PDF === outputType) &&
    drawingPath
  ) {
    return {
      type: outputType,
      drawingTemplatePath: drawingPath,
    };
  }
  return null;
};

export const transformDCOutputsToPostVariantOutputs = (
  outputs: DCOutputWithVirtualTypes[],
): PostVariantOutputWithVirtualTypes[] =>
  outputs.reduce<PostVariantOutputWithVirtualTypes[]>((acc, output) => {
    const drawingTemplatePath = getDCOutputDrawingPath(output);
    const modelStates = getDCOutputModelStates(output);
    if (isDrawingTemplateBasedOutput(output) && drawingTemplatePath) {
      acc.push({
        type: output.type,
        drawingTemplatePath,
      });
    } else if (isModelStateBasedOutput(output) && modelStates) {
      modelStates.forEach((modelState) => {
        acc.push({
          type: output.type,
          modelState,
        });
      });
    } else if (!modelStates && output.type === OutputType.IAM) {
      acc.push({
        type: output.type,
      });
    }
    return acc;
  }, []);
