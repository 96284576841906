import { SpinnerForButton } from '@mid-react-common/common';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { PostVariantOutputWithVirtualTypes } from 'mid-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import text from '../../../../global/text.json';
import { routes } from '../../../../routes';
import { outputsSettingsIds } from '../../../../tests/helpers/dataTestIds';
import {
  ProductReleaseTitleContainer,
  SettingsButtonContainer,
  SettingsPanelHeaderContainer,
} from '../SettingsPanel.styles';
import useGenerateOutputs from '../useGenerateOutputs';

const settingsPanelText = text.settingsPanel;

interface SettingsPanelHeaderProps {
  productName?: string;
  productRelease?: number;
  selectedOutputs: PostVariantOutputWithVirtualTypes[];
}

const OutputSettingsPanelHeader: React.FC<SettingsPanelHeaderProps> = ({ productName, productRelease, selectedOutputs }) => {
  const { isOutputGenerationDisabled, generateButtonText, handleGenerateOutputsButtonClick, isUploadLocationSet } =
    useGenerateOutputs();
  const theme = useTheme();

  const handleGenerate = () => {
    handleGenerateOutputsButtonClick(selectedOutputs);
  };

  return (
    <SettingsPanelHeaderContainer>
      <ProductReleaseTitleContainer>
        <Typography variant="h1">{productName}</Typography>
        {productRelease && (
          <Typography variant="caption" letterSpacing={1}>{`${settingsPanelText.release} ${productRelease}`}</Typography>
        )}
      </ProductReleaseTitleContainer>
      <SettingsButtonContainer>
        <Button
          variant="outlined"
          component={NavLink}
          to={`../../${routes.products.path}/${routes.instances.path}`}
          data-testid={outputsSettingsIds.assignButton}
        >
          {settingsPanelText.editAssignment}
        </Button>
        <Button
          variant="contained"
          disabled={isOutputGenerationDisabled || selectedOutputs.length === 0 || !isUploadLocationSet}
          onClick={handleGenerate}
          endIcon={
            isOutputGenerationDisabled && <SpinnerForButton size={theme.var.smallButtonSpinnerSize} color="inherit" />
          }
          data-testid={outputsSettingsIds.generateButton}
        >
          <Typography variant="inherit" noWrap>
            {generateButtonText}
          </Typography>
        </Button>
      </SettingsButtonContainer>
    </SettingsPanelHeaderContainer>
  );
};

export default OutputSettingsPanelHeader;
