import { delay, HttpResponse } from 'msw';
import { mockVariantStatusIterator } from './data/dataGeneratorUtils';
import { mockAccount, mockAccount2 } from './data/mockAccounts';
import {
  mockFolder1,
  mockFolder2,
  mockNewFolder,
  mockFolderTree1,
  getSubFolderMockResponse,
  mockHubProjectFolder1,
  mockFolder1Permission,
  mockFolderPermission,
  mockHubProjectFolder2,
  mockFolder2Permission,
} from './data/mockFolders';
import {
  mockFormRules,
  mockJavascriptRules1,
  mockPostProductResponse,
  mockProducts,
  mockProductWithRules3,
  mockProjectIdWithError,
  ruleObjectKeyWError,
  rulesObjectKey,
} from './data/mockProducts';
import { mockProject, mockProject2 } from './data/mockProjects';
import { mockUserProfile } from './data/mockUserProfile';
import { mockUserAnalytics } from './data/mockUserAnalytics';
import {
  mockGetVariantLogsResponse,
  mockGetVariantResponse,
  mockPostVariantResponse,
  mockVariant,
  mockVariant1ForMockProductWithRules3,
  mockVariant2,
  mockVariant3,
} from './data/mockVariants';
import { mockBIM360Document, mockProcessingBIM360Document } from './data/mockBIM360Documents';
import { mockManifest } from './data/mockManifest';
import { mockProductUserConfiguration } from './data/mockUserConfigurations';
import { metadataJSON } from './data/mockMetadata';
import { mockProductRelease2, mockProductReleasesList } from './data/mockProductReleases';
import { OutputStatus, Variant } from '@adsk/offsite-dc-sdk';
import { CheckPermissionPostRequest } from 'mid-types';

const RESPONSE_DELAY = 500;

export const getAccountsHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(
    {
      results: [mockAccount, mockAccount2],
      pagination: {
        limit: 100,
        offset: 0,
        totalResults: 2,
      },
    },
    { status: 200 },
  );
};

export const getProjectsHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(
    {
      results: [mockProject, mockProject2],
      pagination: {
        limit: 100,
        offset: 0,
        totalResults: 2,
      },
    },
    { status: 200 },
  );
};

export const getProjectByIdHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockProject, { status: 200 });
};

export const getFoldersHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({ folders: [mockFolder1, mockFolder2] }, { status: 200 });
};

export const createNewfolder = async ({ request }: any): Promise<Response> => {
  const body = await request.json();
  const { parent_folder_urn, title } = body;
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(
    {
      ...mockNewFolder,
      urn: `${mockNewFolder.urn}-${parent_folder_urn}-${title}`,
      parent_urn: parent_folder_urn,
      title,
    },
    { status: 200 },
  );
};

export const getSubFoldersHandler = async ({ params }: any): Promise<Response> => {
  const result = getSubFolderMockResponse(params.folderUrn as string);
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({ folders: result ? result : [] }, { status: 200 });
};

export const getAllProductsInProjectHandler = async ({ params }: any): Promise<Response> => {
  const projectId = params.projectId as string;
  if (projectId === mockProjectIdWithError) {
    return HttpResponse.error();
  }
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({ results: mockProducts, pagination: {} }, { status: 200 });
};

export const getProductHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockProductWithRules3, { status: 200 });
};

export const getProductReleasesHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({ results: mockProductReleasesList, pagination: {} }, { status: 200 });
};

export const getProductReleaseHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockProductRelease2, { status: 200 });
};

export const bypassRequest = (): void => undefined;

export const getVariantHandler = async ({ params }: any): Promise<Response> => {
  const projectId: string = params.projectId as string;
  const productId: string = params.productId as string;
  const variantId: string = params.variantId as string;
  const outputStatus = mockVariantStatusIterator.next().value;
  const getVariantResponse: Variant = {
    ...mockPostVariantResponse,
    outputs: mockGetVariantResponse.outputs.map((output: any) => ({
      ...output,
      status: outputStatus,
    })),
    // so we mimic returning a new thumbnail value on every getVariant
    // where all the outputs are successful
    thumbnail: outputStatus === OutputStatus.SUCCESS ? `thumnail-${Math.random()}` : '',
    variantId,
    tenancyId: projectId,
    contentId: productId,
  };
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(getVariantResponse, { status: 200 });
};

export const postVariantHandler = async ({ params }: any): Promise<Response> => {
  const projectId: string = params.projectId as string;
  const productId: string = params.productId as string;
  const postVariantResponse: Variant = {
    ...mockPostVariantResponse,
    thumbnail: '',
    tenancyId: projectId,
    contentId: productId,
  };
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(postVariantResponse, { status: 200 });
};

export const getDownloadUrlHandler = async ({ request }: any): Promise<Response> => {
  const { objectKey } = await request.json();

  let mockDownloadUrl = 'https://s3.aws.com/path/to/thumbnail.png';
  if (objectKey === rulesObjectKey) {
    mockDownloadUrl = 'https://s3.aws.com/path/to/rules.json';
  }
  if (objectKey === ruleObjectKeyWError) {
    mockDownloadUrl = 'https://s3.aws.com/path/to/rulesWithError.json';
  }
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({ signedUrl: mockDownloadUrl }, { status: 200 });
};

export const postUploadUrls = async (): Promise<Response> => {
  const response = {
    urls: ['https://s3.aws.com/path/to/thumbnail.png'],
    uploadKey: 'mock upload key',
    uploadExpiration: 123,
    urlExpiration: 124,
    objectKey: 'mock-object-key',
  };
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(response, { status: 200 });
};

export const postCompleteUpload = async (): Promise<Response> => {
  const response = {
    contentType: 'mock content type',
    fileName: 'mock file name',
    fileSize: 123,
    objectKey: 'mock object key',
  };
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(response, { status: 200 });
};

export const postProduct = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockPostProductResponse, { status: 200 });
};

export const putUploadUrl = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(null, { status: 200 });
};

export const deleteProductTemplateHandler = async ({ params }: any): Promise<Response> => {
  const productId = params.productId as string;
  if (productId === 'not-existed') {
    return HttpResponse.error();
  }
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(null, { status: 204 });
};

export const getVariantLog = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockGetVariantLogsResponse, { status: 200 });
};

export const getRulesObject = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(
    [
      {
        key: 'currentRule',
        code: mockJavascriptRules1,
      },
      {
        key: 'formRules',
        code: JSON.stringify(mockFormRules),
      },
    ],
    { status: 200 },
  );
};

export const getRulesObjectWithError = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.error();
};

export const getUserProfile = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockUserProfile, { status: 200 });
};

export const getFolderTreeHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({ folder_tree: [mockFolderTree1] }, { status: 200 });
};

export const getAnalyticsId = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockUserAnalytics, { status: 200 });
};

export const getFolderContentHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(
    {
      documents: [mockBIM360Document, mockProcessingBIM360Document],
    },
    { status: 200 },
  );
};

export const getManifestHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockManifest, { status: 200 });
};

export const getThumbnailHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json('mock blob data', { status: 200 });
};

export const getVariantsByProductHandler = async ({ params }: any): Promise<Response> => {
  const projectId: string = params.projectId as string;
  const productId: string = params.productId as string;

  if (
    projectId === mockVariant1ForMockProductWithRules3.tenancyId &&
    productId === mockVariant1ForMockProductWithRules3.contentId
  ) {
    return HttpResponse.json({ pagination: {}, results: [mockVariant1ForMockProductWithRules3] }, { status: 200 });
  }

  if (projectId === mockVariant.tenancyId && productId === mockVariant.contentId) {
    return HttpResponse.json({ pagination: {}, results: [mockVariant] }, { status: 200 });
  }
  if (projectId === mockVariant2.tenancyId && productId === mockVariant2.contentId) {
    return HttpResponse.json({ pagination: {}, results: [mockVariant2] }, { status: 200 });
  }
  if (projectId === mockVariant3.tenancyId && productId === mockVariant3.contentId) {
    return HttpResponse.json({ pagination: {}, results: [mockVariant3] }, { status: 200 });
  }

  return HttpResponse.error();
};

export const getVariantOutputsHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockVariant.outputs, { status: 200 });
};

export const getMSIMetadata = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(metadataJSON, { status: 200 });
};

export const putProductUserConfigurationHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(mockProductUserConfiguration, { status: 200 });
};

export const amplitudeHandler = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({}, { status: 200 });
};

export const postLDServerHash = async (): Promise<Response> => {
  const response = 'mock-hash';
  await delay(RESPONSE_DELAY);
  return HttpResponse.text(response, { status: 200 });
};

export const checkHubProjectFolderPermissionHandler = async ({ request }: any): Promise<Response> => {
  const body: CheckPermissionPostRequest = await request.json();

  if (body.data.relationships.resources.data.length === 1) {
    const requestFolderId = body.data.relationships.resources.data[0].id;
    const permissionResponse =
      requestFolderId === mockHubProjectFolder1.id
        ? mockFolder1Permission
        : requestFolderId === mockHubProjectFolder2.id
        ? mockFolder2Permission
        : mockFolderPermission;
    await delay(RESPONSE_DELAY);
    return HttpResponse.json({ data: permissionResponse }, { status: 200 });
  }
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({ data: mockFolderPermission }, { status: 200 });
};

export const getLDClientStream = async (): Promise<Response> => {
  const encoder = new TextEncoder();
  const stream = new ReadableStream({
    start(controller) {
      controller.enqueue(encoder.encode('Server'));
      controller.enqueue(encoder.encode('Sent'));
      controller.enqueue(encoder.encode('Events'));
      controller.close();
    },
  });

  // Send the mocked response immediately.
  return new HttpResponse(stream, {
    headers: {
      'Content-Type': 'text/event-stream',
    },
  });
};

export const getLDEvents = async (): Promise<Response> => {
  const response = { data: '123' };
  await delay(RESPONSE_DELAY);
  return HttpResponse.json(response, { status: 200 });
};

export const getLDApp = async (): Promise<Response> => {
  await delay(RESPONSE_DELAY);
  return HttpResponse.json({}, { status: 200 });
};
