import { ReleaseStatusWithColorBar, useCancellablePromise } from '@mid-react-common/common';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@weave-mui/data-grid';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { useEffect, useState } from 'react';
import text from '../../global/text.json';

const releasesText = text.releases;

interface ReleaseFolderNameProps {
  tenancyId: string;
  contentId: string;
  folderUrn: string;
}

const ReleaseFolderName: React.FC<ReleaseFolderNameProps> = ({ tenancyId, contentId, folderUrn }) => {
  const [folderData, setFolderData] = useState<JSX.Element>(<></>);
  const cancellablePromise = useCancellablePromise();

  // Update rows with folder separately from initial table data
  useEffect(() => {
    const fetchReleaseFolder = async () => {
      try {
        const folderName = (await cancellablePromise(getForgeApiServiceInstance().getFolder(tenancyId, folderUrn))).title;
        setFolderData(<Typography>{folderName}</Typography>);
      } catch (e) {
        setFolderData(<Typography color="error">{releasesText.failedToFetchFolder}</Typography>);
      }
    };
    fetchReleaseFolder();
  }, [tenancyId, contentId, folderUrn, cancellablePromise]);

  return <>{folderData ? folderData : <Skeleton width="50%" />}</>;
};

export const getColumns: GridColDef[] = [
  {
    field: 'releaseNumber',
    headerName: text.releases.releasesTableColumnReleaseNumber,
    width: 200,
    minWidth: 200,
  },
  {
    field: 'releasedOn',
    headerName: text.releases.releasesTableColumnReleasedOn,
    width: 300,
    minWidth: 200,
  },
  {
    field: 'folder',
    headerName: text.releases.releasesTableColumnFolder,
    width: 300,
    minWidth: 200,
    renderCell: (params) => (
      <ReleaseFolderName
        tenancyId={params.value.tenancyId}
        contentId={params.value.contentId}
        folderUrn={params.value.folderUrn}
      />
    ),
  },
  {
    field: 'status',
    headerName: text.releases.releasesTableColumnStatus,
    width: 300,
    minWidth: 200,
    renderCell: (params) => <ReleaseStatusWithColorBar status={params.value} />,
  },
];
