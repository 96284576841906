import { Variant } from '@adsk/offsite-dc-sdk';
import { NOTIFICATION_STATUSES, NotificationContext } from '@mid-react-common/common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getDcApiServiceInstance } from 'mid-api-services';
import { PostVariantOutputWithVirtualTypes } from 'mid-types';
import { logError } from 'mid-utils';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { ampli } from '../../../ampli';
import ProductContext from '../../../context/ProductStore/Product.context';
import UploadLocationContext from '../../../context/UploadLocationStore/UploadLocation.context';
import text from '../../../global/text.json';
import { transformToVariantPayload } from '../../ProductsPage/InstancesPanel/instancesPanel.utils';
import {
  GeneratingState,
  calculateCost,
  getButtonTextByGeneratingState,
  getVariantIdProductMap,
  updateProductUserConfiguration,
} from './useGenerateOutputs.utils';

const outputsText = text.useGenerateOutputs;
const productsText = text.productsStore;
const commonText = text.common;
export interface UseGenerateOutputsState {
  isOutputGenerationDisabled: boolean;
  generateButtonText: string;
  handleGenerateOutputsButtonClick: (selectedOutputs: PostVariantOutputWithVirtualTypes[]) => Promise<void>;
  isUploadLocationSet: boolean;
}

const useGenerateOutputs = (): UseGenerateOutputsState => {
  const [generatingState, setGeneratingState] = useState<GeneratingState>(GeneratingState.NONE);
  const navigate = useNavigate();

  const { uploadFolderUrn, uploadLocationProject } = useContext(UploadLocationContext);
  const { productReleases, selectedInstances, selectedProductRelease, instances } = useContext(ProductContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);
  const { enableTokenCharge, useJobProcessingV2 } = useFlags();

  const selectedProductId = selectedProductRelease?.contentId || '';
  const selectedProjectId = selectedProductRelease?.tenancyId;

  const isUploadLocationSet = !!(uploadFolderUrn && uploadLocationProject && selectedProductId && selectedProjectId);

  const saveUserConfig = useCallback(
    async (selectedOutputs: PostVariantOutputWithVirtualTypes[]) => {
      if (!isUploadLocationSet) {
        return;
      }

      try {
        await updateProductUserConfiguration({
          selectedOutputs,
          uploadFolderUrn,
          accountId: uploadLocationProject.accountId,
          projectId: uploadLocationProject.id,
          selectedProjectId,
          selectedProductId,
          vendor: uploadLocationProject.vendor,
        });
      } catch (error) {
        logAndShowNotification({
          message: outputsText.failSaveConfigurations,
          error,
          logExtraData: {
            uploadLocationAccountId: uploadLocationProject.accountId,
            uploadLocationProjectId: uploadLocationProject.id,
            uploadFolderUrn,
            productId: selectedProductId,
          },
        });

        throw new Error('Failed to save user config.');
      }
    },
    [
      isUploadLocationSet,
      uploadFolderUrn,
      uploadLocationProject?.accountId,
      uploadLocationProject?.id,
      selectedProductId,
      selectedProjectId,
      uploadLocationProject?.vendor,
      logAndShowNotification,
    ],
  );

  const checkTokenBalance = useCallback(
    async (requestedOutputsCount: number) => {
      try {
        setGeneratingState(GeneratingState.CHECKING_BALANCE);

        const canGenerateOutputsResult = await getDcApiServiceInstance().canGenerateOutputs(requestedOutputsCount);

        if (!canGenerateOutputsResult.canGenerateOutputs) {
          throw new Error(canGenerateOutputsResult.errorMessage);
        }
      } catch (error) {
        logAndShowNotification({
          message: '',
          error,
          logExtraData: { selectedProjectId, uploadFolderUrn, productId: selectedProductId },
        });

        throw new Error('Failed to check tokens balance.');
      }
    },
    [logAndShowNotification, selectedProjectId, selectedProductId, uploadFolderUrn],
  );

  const generateOutputs = useCallback(
    async (selectedOutputs: PostVariantOutputWithVirtualTypes[]) => {
      try {
        const variantIdProductMap = selectedInstances
          ? getVariantIdProductMap(selectedInstances, productReleases, selectedProductId)
          : {};

        const allGenerateOutputsJobs: Promise<void>[] = Object.keys(variantIdProductMap).map(async (currentVariantId) => {
          const currentProduct = variantIdProductMap[currentVariantId];
          try {
            // Product can come from other projects, so here we use the tenancyId from the product
            const currentVariant = await getDcApiServiceInstance().getVariant(
              currentProduct.tenancyId,
              currentProduct.contentId,
              currentVariantId,
            );

            // info required for aggregated BOM
            const numberOfInstancesPerCurrentVariant = selectedInstances!.filter(
              (selectedInstance) => selectedInstance.variantId === currentVariantId,
            ).length;

            // the variant name has to be taken from LMV instances, not from the variant object itself (otherwise all
            // outputs will come under the same folder with the name equals to the product name) TRADES-4912
            const variantName = instances?.find((instance) => instance.variantId === currentVariantId)?.variantName;

            // Transform to variant payload
            const postVariantPayload = transformToVariantPayload(
              variantName || currentVariant.name,
              currentVariant.inputs,
              selectedOutputs,
              numberOfInstancesPerCurrentVariant,
              currentVariant.release,
            );

            // Trigger Post Variant job processing
            const postVariantResponse: Variant = useJobProcessingV2
              ? await getDcApiServiceInstance().uploadVariantOutputs(
                  currentProduct.tenancyId,
                  currentProduct.contentId,
                  postVariantPayload,
                )
              : await getDcApiServiceInstance().postVariant(
                  currentProduct.tenancyId,
                  currentProduct.contentId,
                  postVariantPayload,
                );

            // Amplitude event
            ampli.midwOutputsGenerate({
              projectId: currentProduct.tenancyId,
              productId: currentProduct.contentId,
              variantId: currentVariantId,
              outputTypes: selectedOutputs.map((output) => output.type),
              instancesPerVariant: numberOfInstancesPerCurrentVariant,
              outputsCount: selectedOutputs.length,
              releaseNumber: currentVariant.release,
            });

            showNotification({
              severity: NOTIFICATION_STATUSES.SUCCESS,
              message: `${outputsText.successTriggerJob} ${postVariantResponse.name}`,
            });
          } catch (error) {
            logAndShowNotification({
              message: outputsText.failTriggerJob,
              error,
              logExtraData: { projectId: currentProduct.tenancyId, productId: currentProduct.contentId, currentVariantId },
            });
          }
        });

        await Promise.all(allGenerateOutputsJobs);

        navigate(`../${routes.review.path}`);
      } catch {
        logAndShowNotification({
          message: productsText.failedToFetchAssociateProducts,
        });

        throw new Error('Failed to generate outputs.');
      }
    },
    [instances, logAndShowNotification, navigate, productReleases, selectedInstances, selectedProductId, showNotification],
  );

  const handleGenerateOutputsButtonClick = useCallback(
    async (selectedOutputs: PostVariantOutputWithVirtualTypes[]) => {
      if (selectedInstances && selectedInstances.length <= 0) {
        return logAndShowNotification({ message: outputsText.selectAtLeastOneInstance });
      }

      if (!selectedProductRelease) {
        return logAndShowNotification({ message: productsText.failedToFetchAssociateProducts });
      }

      if (!uploadLocationProject?.id || !uploadLocationProject?.accountId) {
        return logAndShowNotification({ message: commonText.noProjectSelected });
      }

      try {
        if (enableTokenCharge) {
          const outputsToGenerateCount = calculateCost(
            selectedOutputs,
            selectedInstances,
            productReleases,
            selectedProductId,
          );

          setGeneratingState(GeneratingState.CHECKING_BALANCE);

          await checkTokenBalance(outputsToGenerateCount);
        }

        setGeneratingState(GeneratingState.GENERATING);

        await saveUserConfig(selectedOutputs);

        await generateOutputs(selectedOutputs);
      } catch (error) {
        logError(error);
      } finally {
        setGeneratingState(GeneratingState.NONE);
      }
    },
    [
      selectedInstances,
      selectedProductRelease,
      uploadLocationProject?.id,
      uploadLocationProject?.accountId,
      logAndShowNotification,
      enableTokenCharge,
      saveUserConfig,
      generateOutputs,
      productReleases,
      selectedProductId,
      checkTokenBalance,
    ],
  );

  return {
    isOutputGenerationDisabled: generatingState !== GeneratingState.NONE,
    generateButtonText: getButtonTextByGeneratingState(generatingState),
    handleGenerateOutputsButtonClick,
    isUploadLocationSet,
  };
};

export default useGenerateOutputs;
