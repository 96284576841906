import { ProductRelease, ReleaseStatus } from '@adsk/offsite-dc-sdk';
import { CustomDialog, ReleaseStatusWithColorBar, LinkButton, midTheme } from '@mid-react-common/common';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import text from '../../../global/text.json';
import { releaseUpdateIds } from '../../../tests/helpers/dataTestIds';
import {
  ReleaseProductName,
  ReleaseStatusLabel,
  ReleaseStatusOptionsContainer,
  ReleaseStatusRow,
  ReleaseStatusUpdateSpinner,
} from '../Releases.styles';
import ReleaseUpdateSelectNewDefault from './ReleaseUpdateSelectNewDefault';
import ReleaseUpdateWarningMessage from './ReleaseUpdateWarningMessage';
import useReleaseStatusUpdate from './useReleaseStatusUpdate';

export const ReleaseStatusUpdateButtonVariant = {
  button: 'button',
  linkButton: 'linkButton',
} as const;

export type ReleaseStatusUpdateButtonVariantType =
  (typeof ReleaseStatusUpdateButtonVariant)[keyof typeof ReleaseStatusUpdateButtonVariant];

interface ReleaseStatusUpdateProps {
  productReleases: ProductRelease[];
  releaseNumber: number;
  setReloadReleaseData: (reload: boolean) => void;
  buttonVariant?: ReleaseStatusUpdateButtonVariantType;
  buttonText?: string;
}

const releasesText = text.releases;
const ReleaseStatusUpdate: React.FC<ReleaseStatusUpdateProps> = ({
  productReleases,
  releaseNumber,
  buttonText,
  buttonVariant = ReleaseStatusUpdateButtonVariant.button,
  setReloadReleaseData,
}) => {
  const {
    dialogOpen,
    handleDialogClose,
    handleDialogOpen,
    activeReleases,
    currentRelease,
    isStatusUpdating,
    newDefaultRelease,
    newReleaseStatus,
    obsoleteReleasesCount,
    releaseCount,
    releaseStatusOptions,
    setNewDefaultRelease,
    updateReleaseStatus,
  } = useReleaseStatusUpdate({ productReleases, releaseNumber, setReloadReleaseData });

  if (!currentRelease) {
    return null;
  }

  const isLastActiveRelease =
    (currentRelease.status === ReleaseStatus.DEFAULT || currentRelease.status === ReleaseStatus.ACTIVE) &&
    releaseCount - obsoleteReleasesCount === 1;

  return (
    <>
      {buttonVariant === ReleaseStatusUpdateButtonVariant.linkButton && (
        <LinkButton onClick={handleDialogOpen} data-testid={releaseUpdateIds.releaseUpdateButton} leftMargin>
          {buttonText || releasesText.updateStatus}
        </LinkButton>
      )}

      {buttonVariant === ReleaseStatusUpdateButtonVariant.button && (
        <Button onClick={handleDialogOpen} variant="contained" data-testid={releaseUpdateIds.releaseUpdateButton}>
          {buttonText || releasesText.updateStatus}
        </Button>
      )}

      <CustomDialog
        title={releasesText.updateReleaseStatus}
        open={dialogOpen}
        dividers
        maxWidth="md"
        customActions={[
          <Button
            key={releaseUpdateIds.releaseUpdateCancelButton}
            data-testid={releaseUpdateIds.releaseUpdateCancelButton}
            variant="outlined"
            onClick={handleDialogClose}
          >
            {releasesText.cancel}
          </Button>,
          <Button
            key={releaseUpdateIds.releaseUpdateUpdateButton}
            data-testid={releaseUpdateIds.releaseUpdateUpdateButton}
            variant="contained"
            onClick={updateReleaseStatus}
            startIcon={isStatusUpdating && <ReleaseStatusUpdateSpinner size={midTheme.var.buttonSpinnerSize} />}
            disabled={isLastActiveRelease}
          >
            {releasesText.update}
          </Button>,
        ]}
      >
        <Box>
          <ReleaseStatusRow>
            <ReleaseStatusLabel>{releasesText.release}</ReleaseStatusLabel>
            <Typography>
              <ReleaseProductName>{currentRelease.name}</ReleaseProductName>
              <span>{`${releaseNumber} ${releasesText.of} ${releaseCount}`}</span>
            </Typography>
          </ReleaseStatusRow>
          <ReleaseStatusRow data-testid={releaseUpdateIds.releaseUpdateCurrentStatus}>
            <ReleaseStatusLabel>{releasesText.currentStatus}</ReleaseStatusLabel>
            <ReleaseStatusWithColorBar status={currentRelease.status} />
          </ReleaseStatusRow>
          <ReleaseStatusRow>
            {!isLastActiveRelease && <ReleaseStatusLabel>{releasesText.setStatusTo}</ReleaseStatusLabel>}
            <ReleaseStatusOptionsContainer data-testid={releaseUpdateIds.releaseUpdateOptions}>
              {releaseStatusOptions}
              {currentRelease && (
                <ReleaseUpdateWarningMessage
                  newReleaseStatus={newReleaseStatus}
                  currentRelease={currentRelease}
                  releaseCount={releaseCount}
                  obsoleteReleasesCount={obsoleteReleasesCount}
                  isLastActiveRelease={isLastActiveRelease}
                />
              )}
            </ReleaseStatusOptionsContainer>
          </ReleaseStatusRow>
          {currentRelease && currentRelease.status === ReleaseStatus.DEFAULT && activeReleases.length > 1 && (
            <ReleaseUpdateSelectNewDefault
              activeReleases={activeReleases}
              releaseCount={releaseCount}
              newDefaultRelease={newDefaultRelease}
              setNewDefaultRelease={setNewDefaultRelease}
            />
          )}
        </Box>
      </CustomDialog>
    </>
  );
};

export default ReleaseStatusUpdate;
