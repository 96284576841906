import { DynamicContent, ProductRelease } from '@adsk/offsite-dc-sdk';
import { useCancellablePromise } from '@mid-react-common/common';
import { GridRowId } from '@weave-mui/data-grid';
import { useThumbnail } from 'global/hooks/hooks';
import { logError } from 'mid-utils';
import { useEffect, useState } from 'react';
import text from '../../../global/text.json';
import { getPublishLocationPathFromFolderPath } from './ReleaseDetails.utils';

const releasesText = text.releases;

interface UseReleaseDetailsState {
  selectedIndex: number;
  parameterVisibility: boolean;
  releaseFullPath: string[] | Error;
  thumbnail: string | null;
  isThumbnailLoading: boolean;
  currentRelease: ProductRelease | undefined;
  handleShowAllClick: () => void;
  handleReleaseDetailsPageChange: (index: number) => void;
}

interface UseReleaseDetailsProps {
  productReleases: ProductRelease[];
  selectedProduct: DynamicContent | null;
  selectedReleasesIds: GridRowId[];
}

const useReleaseDetails = ({
  productReleases,
  selectedProduct,
  selectedReleasesIds,
}: UseReleaseDetailsProps): UseReleaseDetailsState => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [parameterVisibility, setParameterVisibility] = useState<boolean>(false);
  const [releaseFullPath, setReleaseFullPath] = useState<string[] | Error>([]);
  const [selectedReleases, setSelectedReleases] = useState<ProductRelease[]>([]);
  const [currentRelease, setCurrentRelease] = useState<ProductRelease>();

  const cancellablePromise = useCancellablePromise();

  const { thumbnail, isThumbnailLoading } = useThumbnail({
    thumbnailId: currentRelease?.thumbnail,
    thumbnailProjectId: currentRelease?.tenancyId,
  });

  const handleShowAllClick = () => {
    setParameterVisibility((parameterVisibility) => !parameterVisibility);
  };

  const handleReleaseDetailsPageChange = (index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedReleases(productReleases.filter((release) => selectedReleasesIds.includes(release.release)));
  }, [productReleases, selectedReleasesIds]);

  useEffect(() => {
    if (selectedReleasesIds) {
      setCurrentRelease(selectedReleases[selectedIndex]);
    }
  }, [selectedIndex, selectedReleases, selectedReleasesIds]);

  useEffect(() => {
    const fetchReleaseFullPath = async () => {
      if (currentRelease) {
        try {
          const fullPathObject = await cancellablePromise(getPublishLocationPathFromFolderPath(currentRelease));
          const fullPath: string[] = [fullPathObject.name];
          fullPathObject.parentPath.forEach((urn) => fullPath.push(urn.name));
          setReleaseFullPath(fullPath.reverse());
        } catch (e) {
          logError({ message: releasesText.failedToFetchFolderPath, error: e });
          setReleaseFullPath(new Error(releasesText.failedToFetchFolderPath));
        }
      }
    };
    fetchReleaseFullPath();
  }, [currentRelease, setReleaseFullPath, cancellablePromise]);

  // Must reset the selected index when the selected product changes or when the selected releases are empty
  useEffect(() => {
    if (selectedReleases.length === 0) {
      setSelectedIndex(0);
    }
    if (selectedReleases.length && selectedReleases.length <= selectedIndex) {
      setSelectedIndex(selectedReleases.length - 1);
    }
  }, [selectedIndex, selectedReleases.length]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [selectedProduct]);

  return {
    isThumbnailLoading,
    thumbnail,
    parameterVisibility,
    selectedIndex,
    releaseFullPath,
    currentRelease,
    handleShowAllClick,
    handleReleaseDetailsPageChange,
  };
};

export default useReleaseDetails;
