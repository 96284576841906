import { NavItem, Sidebar } from 'pages/Sidebar';
import { useContext } from 'react';
import { Outlet, generatePath } from 'react-router-dom';
import ModelSelectionContext from '../../context/ModelSelectionStore/ModelSelection.context';
import { useDefaultNavigation } from '../../global/hooks/hooks';
import text from '../../global/text.json';
import InstancesIcon from '../../images/instances.svg?react';
import ModelsIcon from '../../images/models.svg?react';
import { routes } from '../../routes';

const ProductsPage = (): JSX.Element => {
  const { currentlyOpenModel, selectedFolderUrn, selectedModelId } = useContext(ModelSelectionContext);
  useDefaultNavigation(routes.products.id, routes.models.path);

  // dynamically generate route to the Models page
  const getModelsRoute = () => {
    if (selectedFolderUrn && selectedModelId) {
      return generatePath([routes.models.path, routes.folders.path, routes.folderItems.path].join('/'), {
        folderUrn: selectedFolderUrn,
        itemUrn: selectedModelId,
      });
    }

    return routes.models.path;
  };

  const navItems: NavItem[] = [
    { name: text.productsSubNavs.models, path: getModelsRoute(), icon: <ModelsIcon /> },
    {
      name: text.productsSubNavs.instances,
      path: routes.instances.path,
      icon: <InstancesIcon />,
      disabled: !currentlyOpenModel,
    },
  ];

  return (
    <>
      <Sidebar navItems={navItems} />
      <Outlet />
    </>
  );
};

export default ProductsPage;
