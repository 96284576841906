import AccountProjectContext from 'context/AccountProjectStore/AccountProject.context';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootNodeErrorReasons } from '../../Commons/FoldersTree/useFoldersTree';

export interface UseModelFolderBrowser {
  error: any;
}

const useModelFolderBrowser = ({ error }: UseModelFolderBrowser): void => {
  const { clearProjectData } = useContext(AccountProjectContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      // If folder doesn't exists or returns an error, go back to 'root' folder
      if (error.reason === rootNodeErrorReasons.NOT_ROOT) {
        navigate('..');
      }

      if (error.reason === rootNodeErrorReasons.ROOT) {
        // If getting the 'root' folder of a project returns an error
        // ask the user to select a new project (fixes: TRADES-4141)
        clearProjectData({ updateLocalStorage: true });
        navigate('/');
      }
    }
  }, [clearProjectData, error, navigate]);
};

export default useModelFolderBrowser;
