import { NotificationContext, StateSetter } from '@mid-react-common/common';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllMIDElements } from '../../components/ProductsPage/InstancesPanel/hooks/useMIDElementsFromViewer';
import { FolderContentRow } from '../../components/ProductsPage/ModelsFolderContent/ModelsFolderContent.types';
import AccountProjectContext from '../../context/AccountProjectStore/AccountProject.context';
import GateKeeperContext from '../../context/GatekeeperStore/Gatekeeper.context';
import ModelSelectionContext from '../../context/ModelSelectionStore/ModelSelection.context';
import ProductContext from '../../context/ProductStore/Product.context';
import { MODEL_VIEWER_TIMER } from '../../global/constants/model';
import { useNavigationRoutines } from '../../global/hooks/hooks';
import text from '../../global/text.json';
import viewerService from '../../services/viewer/viewerService';
import { Instance } from '../../types/product';

const loadInstancesData = async (
  token: string,
  projectId: string,
  lmvModelFileId: string,
  setInstances: StateSetter<Instance[] | undefined>,
  lmvContainer: React.RefObject<HTMLDivElement>,
) => {
  if (lmvContainer.current) {
    lmvContainer.current.appendChild(viewerService.viewerNode);
  }

  await viewerService.initializeViewer({
    documentId: lmvModelFileId,
    projectId,
    getAccessToken: (get) => {
      get(token, MODEL_VIEWER_TIMER);
    },
  });

  viewerService.loadDocument(projectId, lmvModelFileId);

  viewerService.viewer.waitForLoadDone().then(async () => {
    await getAllMIDElements(lmvModelFileId, setInstances);

    // cleanup viewer service after getting needed data (to make it possible to reopen it in the Instances page)
    viewerService.viewer.unloadModel();
    viewerService.documentId = undefined;
  });
};

const useOpenModelURLHandling = (lmvContainer: React.RefObject<HTMLDivElement>): void => {
  const { projectId } = useContext(AccountProjectContext);
  const { getFreshToken } = useContext(GateKeeperContext);
  const { currentlyOpenModel, selectedFolderUrn, setCurrentlyOpenModel, setSelectedFolderUrn, setSelectedModelId } =
    useContext(ModelSelectionContext);
  const { logAndShowNotification } = useContext(NotificationContext);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { parseOpenModelURLParameter, serializeOpenModelDataAsURLParameter } = useNavigationRoutines();
  const openModelURLParameter = searchParams.get('openModel');

  const { instances, setInstances } = useContext(ProductContext);

  // when the info about the opened model is available ONLY in URL parameter
  useEffect(() => {
    if (!projectId) {
      return;
    }

    const restoreOpenModelStateByURLParameter = async () => {
      try {
        if (openModelURLParameter) {
          const parsedOpenModel = parseOpenModelURLParameter(openModelURLParameter);

          setCurrentlyOpenModel({
            lmvModelFileId: parsedOpenModel.lmvModelFileId,
            id: parsedOpenModel.itemUrn,
          } as FolderContentRow);

          const token = await getFreshToken();
          loadInstancesData(token, projectId, parsedOpenModel.lmvModelFileId, setInstances, lmvContainer);

          setSelectedFolderUrn(parsedOpenModel.folderUrn);
          setSelectedModelId(parsedOpenModel.itemUrn);
        }
      } catch (error) {
        logAndShowNotification({ error, message: text.common.malformedOpenModel });

        // got back to Models page
        navigate('../..');
      }
    };

    const shouldRestoreOpenModelStateByURLParameter =
      !currentlyOpenModel && openModelURLParameter && !viewerService.initialized;

    if (shouldRestoreOpenModelStateByURLParameter) {
      restoreOpenModelStateByURLParameter();
    }
  }, [
    currentlyOpenModel,
    lmvContainer,
    logAndShowNotification,
    navigate,
    openModelURLParameter,
    parseOpenModelURLParameter,
    projectId,
    setCurrentlyOpenModel,
    setInstances,
    setSelectedFolderUrn,
    setSelectedModelId,
    getFreshToken,
  ]);

  // when currentlyOpenModel is set, but the Instances tab hasn't been opened before
  useEffect(() => {
    if (!projectId) {
      return;
    }

    const restoreOpenModelStateByCurrentlyOpenModel = async () => {
      if (currentlyOpenModel) {
        const token = await getFreshToken();
        loadInstancesData(token, projectId, currentlyOpenModel.lmvModelFileId, setInstances, lmvContainer);
      }
    };

    const shouldRestoreOpenModelStateByCurrentlyOpenModel = !instances && currentlyOpenModel && !viewerService.initialized;

    if (shouldRestoreOpenModelStateByCurrentlyOpenModel) {
      restoreOpenModelStateByCurrentlyOpenModel();
    }
  }, [currentlyOpenModel, instances, lmvContainer, projectId, setInstances, getFreshToken]);

  // when the model is opened, but it's not yet reflected in the URL parameter
  useEffect(() => {
    if (!projectId) {
      return;
    }

    const shouldStoreOpenModelStateInURLParameter = currentlyOpenModel && !openModelURLParameter;

    if (shouldStoreOpenModelStateInURLParameter) {
      searchParams.set('openModel', serializeOpenModelDataAsURLParameter(currentlyOpenModel, selectedFolderUrn));
      setSearchParams(searchParams);
    }
  }, [
    currentlyOpenModel,
    openModelURLParameter,
    projectId,
    searchParams,
    selectedFolderUrn,
    serializeOpenModelDataAsURLParameter,
    setSearchParams,
  ]);
};

export default useOpenModelURLHandling;
