import { inversifyContainer, DcApiService, InversifyTypes } from 'mid-api-services';
import { ProductReleasesQueryParameters, GetAllProductReleasesResult } from 'mid-types';
import { processAllSettledResults } from 'mid-utils';
import { ProductRelease } from '@adsk/offsite-dc-sdk';

export const getAllProductReleases = async (
  queryParameters: ProductReleasesQueryParameters[],
): Promise<GetAllProductReleasesResult | null> => {
  if (queryParameters.length === 0) {
    return null;
  }

  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);

  //loop though all products and get all releases
  const resultsPromise = queryParameters.map(
    async (ids): Promise<ProductRelease[]> => await dcApiService.getProductReleasesList(ids.projectId, ids.productId),
  );

  const allSettledResult = await Promise.allSettled<ProductRelease[]>(resultsPromise);
  return processAllSettledResults(allSettledResult);
};
