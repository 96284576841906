import {
  AuthenticationError,
  getReactAppEnvironment,
  logError,
  VariantOutputFetchError,
  VariantPostError,
  VariantLogsFetchError,
  EmptyOutputFileError,
  ServiceTypes,
  processAllSettledResults,
  migrateVariantMultiValueInputs,
} from 'mid-utils';
import text from '../global/text.json';
import { DownloadURLPayload, DownloadUrlResponse, PostVariantPayload, Variant, VariantOutput } from '@adsk/offsite-dc-sdk';
import {
  ApiServiceFactory,
  DcApiService,
  InversifyTypes,
  getDcApiServiceInstance,
  inversifyContainer,
} from 'mid-api-services';
import { VariantsQueryParameters, BatchFetchVariantsResult } from 'mid-types';

const variantsServiceText = text.variantsService;
const apiServiceText = text.apiService;
const currentEnv = getReactAppEnvironment();

export async function getVariantsList(
  projectId: string,
  productId: string,
  enableMultiValuesBackwardsCompatibility: boolean,
): Promise<Variant[]> {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  const variants: Variant[] = await dcApiService.getVariantsList(projectId, productId);

  if (enableMultiValuesBackwardsCompatibility) {
    return variants.map(migrateVariantMultiValueInputs);
  }

  return variants;
}

export async function batchFetchVariants(
  variantsParameters: VariantsQueryParameters[],
  enableMultiValuesBackwardsCompatibility: boolean,
): Promise<BatchFetchVariantsResult | null> {
  if (variantsParameters.length === 0) {
    return null;
  }

  const resultsPromise = variantsParameters.map(
    async (variantsParams) =>
      await getVariantsList(variantsParams.projectId, variantsParams.productId, enableMultiValuesBackwardsCompatibility),
  );
  const results = await Promise.allSettled<Variant[]>(resultsPromise);
  return processAllSettledResults(results);
}

export const postVariantToAPI = async (
  token: string,
  projectId: string,
  productId: string,
  variantPayload: PostVariantPayload,
): Promise<Variant> => {
  if (!token) {
    throw new AuthenticationError(apiServiceText.unauthorizedAccessError);
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });

  const path = `projects/${projectId}/products/${productId}/variants`;
  try {
    const { response } = apiService.abortablePost(path, variantPayload);
    return (await response).data;
  } catch (e) {
    logError(e);
    throw new VariantPostError(variantsServiceText.variantPostError, { projectId, productId, variantPayload });
  }
};

export const getVariantOutputs = async (
  token: string,
  projectId: string,
  productId: string,
  variantId: string,
): Promise<VariantOutput[]> => {
  if (!token) {
    throw new AuthenticationError(apiServiceText.unauthorizedAccessError);
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });

  const path = `projects/${projectId}/products/${productId}/variants/${variantId}/outputs`;

  try {
    const { response } = apiService.abortableGet(path);
    return (await response).data;
  } catch (e) {
    logError(e);
    throw new VariantOutputFetchError(variantsServiceText.variantOutputsLoadError, { productId, projectId, variantId });
  }
};

export const getVariantLogsText = async (fileUrl: string): Promise<string> => {
  try {
    const response = await fetch(new URL(fileUrl));
    return (await response).text();
  } catch (e) {
    logError(e);
    throw new VariantLogsFetchError(variantsServiceText.variantOutputsLogFile, { fileUrl });
  }
};

interface getVariantLogsResponse {
  fileUrl: string;
  logData: string;
}

export const getVariantsLogs = async (
  projectId: string,
  productId: string,
  variantId: string,
): Promise<getVariantLogsResponse> => {
  const { urn } = await getDcApiServiceInstance().getVariantLog(projectId, productId, variantId);
  const downloadURLPayload: DownloadURLPayload = {
    objectKey: urn,
  };
  const downloadUrlResponse: DownloadUrlResponse = await getDcApiServiceInstance().downloadURL(
    projectId,
    downloadURLPayload,
  );

  if (!downloadUrlResponse.signedUrl) {
    throw new EmptyOutputFileError(text.reviewPanel.emptyFileUrlFromAPI);
  }

  const fileUrl = downloadUrlResponse.signedUrl;
  const logData = await getVariantLogsText(fileUrl);

  if (!logData) {
    throw new EmptyOutputFileError(text.reviewPanel.emptyFileUrlFromAPI);
  }

  return { fileUrl, logData };
};
