import { StateSetter } from '@mid-react-common/common';
import { useCallback, useContext, useState } from 'react';
import UploadLocationContext from '../../../../../context/UploadLocationStore/UploadLocation.context';
import { Tree, TreeNode } from '../../../../../types/common';
import { UploadLocationProject } from '../../../../../types/uploadLocationProject';

interface UseSelectionModalState {
  uploadFolderUrnModalSelection: string | null;
  uploadLocationTreeModalSelection: Tree | undefined;
  expandedUploadLocationTreeNodeIdsModalSelection: string[];
  uploadLocationProject: UploadLocationProject | null;
  dialogOpen: boolean;
  isSubfolderCreationInputVisible: boolean;
  isFolderBreadcrumbSet: boolean;
  editPermissionLookup: Record<string, boolean>;
  setUploadLocationTreeModalSelection: StateSetter<Tree | undefined>;
  setExpandedUploadLocationTreeNodeIdsModalSelection: StateSetter<string[]>;
  handleProjectSelection: (project: UploadLocationProject) => void;
  handleDialogOpen: () => void;
  handleDialogClose: () => void;
  handleFolderSelection: (folderUrn: string) => void;
  handleUploadLocationConfirmation: () => void;
  handleSubfolderCreationDisplay: () => void;
  dismissSubfolderCreationDisplay: () => void;
  setEditPermissionLookup: StateSetter<Record<string, boolean>>;
}

export const useSelectionModal = (): UseSelectionModalState => {
  const {
    uploadLocationBreadcrumbs,
    setUploadLocationBreadcrumbs,
    uploadLocationProject,
    setUploadLocationProject,
    setUploadFolderUrn,
    uploadFolderUrn,
  } = useContext(UploadLocationContext);

  const [uploadLocationProjectModalSelection, setUploadLocationProjectModalSelection] =
    useState<UploadLocationProject | null>(uploadLocationProject);
  const [uploadFolderUrnModalSelection, setUploadFolderUrnModalSelection] = useState<string | null>(null);
  const [uploadLocationTreeModalSelection, setUploadLocationTreeModalSelection] = useState<Tree | undefined>(undefined);
  const [expandedUploadLocationTreeNodeIdsModalSelection, setExpandedUploadLocationTreeNodeIdsModalSelection] = useState<
    string[]
  >([]);

  // When user click on confirm, these state saves folder tree state where urn is selected
  const [uploadFoldersTree, setUploadFoldersTree] = useState<Tree | undefined>(undefined);
  const [expandedUploadLocationTreeNodeIds, setExpanededUploadLocationTreeNodeIds] = useState<string[]>([]);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isSubfolderCreationInputVisible, setIsCreatingSubfolder] = useState<boolean>(false);
  const [editPermissionLookup, setEditPermissionLookup] = useState<Record<string, boolean>>({});

  const initializeFolderTreeStateInModal = useCallback(() => {
    setUploadFolderUrnModalSelection(null);
    setUploadLocationTreeModalSelection(undefined);
    setExpandedUploadLocationTreeNodeIdsModalSelection([]);
  }, [
    setUploadFolderUrnModalSelection,
    setUploadLocationTreeModalSelection,
    setExpandedUploadLocationTreeNodeIdsModalSelection,
  ]);

  const handleProjectSelection = useCallback(
    (project: UploadLocationProject) => {
      if (project.id === uploadLocationProjectModalSelection?.id) {
        return;
      }
      initializeFolderTreeStateInModal();
      setUploadLocationProjectModalSelection(project);
    },
    [initializeFolderTreeStateInModal, setUploadLocationProjectModalSelection, uploadLocationProjectModalSelection],
  );

  const handleUploadLocationConfirmation = useCallback(() => {
    const fullPath: TreeNode[] = [];
    let current = uploadFolderUrnModalSelection;
    if (uploadLocationTreeModalSelection && uploadLocationProjectModalSelection) {
      Object.keys(uploadLocationTreeModalSelection)
        .reverse()
        .forEach((parentFolder) => {
          for (const childFolder of uploadLocationTreeModalSelection[parentFolder]) {
            if (childFolder.id === current) {
              fullPath.push(childFolder);
              current = parentFolder;
            }
          }
        });

      const folderUrn = fullPath[0].id;
      setUploadFolderUrn(folderUrn);
      setUploadLocationBreadcrumbs(fullPath.reverse());

      setUploadLocationProject(uploadLocationProjectModalSelection);

      setUploadFoldersTree(uploadLocationTreeModalSelection);
      setExpanededUploadLocationTreeNodeIds(expandedUploadLocationTreeNodeIdsModalSelection);

      setDialogOpen(false);
      setIsCreatingSubfolder(false);
    }
  }, [
    uploadLocationProjectModalSelection,
    uploadFolderUrnModalSelection,
    uploadLocationTreeModalSelection,
    expandedUploadLocationTreeNodeIdsModalSelection,
    setUploadFolderUrn,
    setUploadLocationBreadcrumbs,
    setUploadLocationProject,
    setUploadFoldersTree,
    setExpanededUploadLocationTreeNodeIds,
    setDialogOpen,
  ]);

  const handleFolderSelection = useCallback(
    (folderUrn: string) => {
      setIsCreatingSubfolder(false);

      if (!uploadLocationProjectModalSelection || folderUrn === uploadFolderUrnModalSelection) {
        return;
      }
      setUploadFolderUrnModalSelection(folderUrn);
    },
    [uploadLocationProjectModalSelection, uploadFolderUrnModalSelection],
  );

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
    setIsCreatingSubfolder(false);
  }, []);

  const handleDialogOpen = useCallback(() => {
    // Reset to remove previous modal selections
    setExpandedUploadLocationTreeNodeIdsModalSelection(expandedUploadLocationTreeNodeIds);
    setUploadFolderUrnModalSelection(uploadFolderUrn);

    // Only reset folder tree if project has changed
    // so project tree can be fetched properly
    if (uploadLocationProject?.id !== uploadLocationProjectModalSelection?.id) {
      setUploadLocationTreeModalSelection(uploadFoldersTree);
      setUploadLocationProjectModalSelection(uploadLocationProject);
    }

    setDialogOpen(true);
  }, [
    expandedUploadLocationTreeNodeIds,
    uploadFolderUrn,
    uploadFoldersTree,
    uploadLocationProjectModalSelection?.id,
    uploadLocationProject,
    setExpandedUploadLocationTreeNodeIdsModalSelection,
    setUploadFolderUrnModalSelection,
    setUploadLocationTreeModalSelection,
    setUploadLocationProjectModalSelection,
    setDialogOpen,
  ]);

  const handleSubfolderCreationDisplay = useCallback(() => {
    setIsCreatingSubfolder(true);

    // If add subfolder is clicked, then automatically expand folder tree
    if (
      uploadFolderUrnModalSelection &&
      !expandedUploadLocationTreeNodeIdsModalSelection.includes(uploadFolderUrnModalSelection)
    ) {
      setExpandedUploadLocationTreeNodeIdsModalSelection([
        ...expandedUploadLocationTreeNodeIdsModalSelection,
        uploadFolderUrnModalSelection,
      ]);
    }
  }, [uploadFolderUrnModalSelection, expandedUploadLocationTreeNodeIdsModalSelection]);

  const dismissSubfolderCreationDisplay = () => {
    setIsCreatingSubfolder(false);
  };

  return {
    uploadFolderUrnModalSelection,
    uploadLocationTreeModalSelection,
    expandedUploadLocationTreeNodeIdsModalSelection,
    uploadLocationProject: uploadLocationProjectModalSelection,
    dialogOpen,
    isSubfolderCreationInputVisible,
    isFolderBreadcrumbSet: !!uploadLocationBreadcrumbs.length,
    editPermissionLookup,
    setUploadLocationTreeModalSelection,
    setExpandedUploadLocationTreeNodeIdsModalSelection,
    handleProjectSelection,
    handleDialogOpen,
    handleDialogClose,
    handleFolderSelection,
    handleUploadLocationConfirmation,
    handleSubfolderCreationDisplay,
    dismissSubfolderCreationDisplay,
    setEditPermissionLookup,
  };
};
