import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NavLink } from 'react-router-dom';
import text from '../../../global/text.json';
import { routes } from '../../../routes';
import { InstancesPanelIds, modelDetails } from '../../../tests/helpers/dataTestIds';

const instancePanelText = text.instancePanel;

interface ExpandToggleButtonProps {
  expanded: boolean;
  onToggle: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ExpandToggleButton = ({ expanded, onToggle }: ExpandToggleButtonProps): JSX.Element => (
  <IconButton onClick={onToggle} data-testid={InstancesPanelIds.expandTableButton}>
    {expanded && <KeyboardDoubleArrowLeft />}
    {!expanded && <KeyboardDoubleArrowRight />}
  </IconButton>
);

interface GenerateOutputsButtonProps {
  disabled: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

export const GenerateOutputsButton = ({ disabled, onClick }: GenerateOutputsButtonProps): JSX.Element => (
  <Tooltip title={instancePanelText.generateOutputs}>
    <LoadingButton
      variant="outlined"
      component={NavLink}
      to={'../../' + routes.outputs.path + '/' + routes.settings.path}
      disabled={disabled}
      data-testid={modelDetails.generateOutputsButton}
      onClick={onClick}
    >
      <Typography variant="inherit" noWrap>
        {instancePanelText.generateOutputs}
      </Typography>
    </LoadingButton>
  </Tooltip>
);
