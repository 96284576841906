import {
  mockAccount,
  mockAccount2,
  mockProject,
  mockProject2,
  mockCodeBlockWorkspaceWithSimpleAssignment,
  mockCodeBlocksWorkspaceMockWithConditionalMultiValueAssignments,
  mockBooleanInput,
  mockNumericInputWithMinValidValue,
  mockCodeBlockWorkspaceWithNumericValue,
  mockCodeBlockWorkspaceWithMultipleInvalidNumericValues,
  mockNumericInputWithDepthMinError,
  mockNumericInputWithHeightMinError,
  mockNumericInputWithWidthMinError,
} from 'mid-utils';
import {
  ProductDefinition,
  ProductDefinitionInputParameter,
  ProductDefinitionIProperty,
} from '../../interfaces/productDefinitions';
import { mockPropertiesProductDefinition1, mockPropertiesProductDefinition2 } from './mockParametersAndIProperties';
import { thumbnailsBase64 } from './mockThumbnailInBase64';
import { InputType, OutputType } from '@adsk/offsite-dc-sdk';

export const mockProductDefinition1Parameters: ProductDefinitionInputParameter[] =
  mockPropertiesProductDefinition1.parameters;
export const mockProductDefinition1IProperties: ProductDefinitionIProperty[] = mockPropertiesProductDefinition1.iProperties;

export const mockNewProductDefinition1: ProductDefinition = {
  name: 'Wall2000',
  releaseName: '',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [...mockProductDefinition1Parameters, ...mockProductDefinition1IProperties],
  parametersDefaults: [],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockProductDefinition1: ProductDefinition = {
  id: '6ca6948f-8646-449a-b1a6-b12ea1dbc990-mockProductDefinition1',
  name: 'Wall2000',
  releaseName: 'Wall2000 First Release',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [...mockProductDefinition1IProperties, ...mockProductDefinition1Parameters],
  parametersDefaults: [],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
  notes: '',
};

export const mockArchivedProductDefinition1: ProductDefinition = {
  id: '6ca6948f-8646-449a-b1a6-b12ea1dbc990',
  name: 'Wall2000',
  releaseName: 'Wall2000 First Release',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [...mockProductDefinition1IProperties, ...mockProductDefinition1Parameters],
  parametersDefaults: [],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
  isArchived: true,
};

export const mockProductDefinition1Duplicated: ProductDefinition = {
  id: 'Xca6948f-8646-449a-b1a6-b12ea1dbc99X-mockProductDefinition1Duplicated',
  name: 'Wall2000 (1)',
  releaseName: 'Wall2000 Released',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [
    {
      base64: thumbnailsBase64.thumbnail1,
      checked: true,
      relativePath: '/Project1/Project2/Assembly.dwg',
    },
  ],
  inputs: [...mockProductDefinition1IProperties, ...mockProductDefinition1Parameters],
  parametersDefaults: [],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockProductDefinition2Parameters: ProductDefinitionInputParameter[] =
  mockPropertiesProductDefinition2.parameters;
export const mockProductDefinition2IProperties: ProductDefinitionIProperty[] = mockPropertiesProductDefinition2.iProperties;
export const mockNewProductDefinition2: ProductDefinition = {
  name: 'Wall3000-mockNewProductDefinition2',
  releaseName: '',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount2.id,
    name: mockAccount2.name,
  },
  project: {
    id: mockProject2.id,
    name: mockProject2.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [...mockProductDefinition2IProperties, ...mockProductDefinition2Parameters],
  parametersDefaults: [],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockProductDefinition2: ProductDefinition = {
  id: '843c1a80-4706-4657-9a69-eb58dbec475f-mockProductDefinition2',
  name: 'Wall3000',
  releaseName: 'Release 3000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount2.id,
    name: mockAccount2.name,
  },
  project: {
    id: mockProject2.id,
    name: mockProject2.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [...mockProductDefinition2IProperties, ...mockProductDefinition2Parameters],
  parametersDefaults: [],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['BIM'],
      },
    },
  ],
};

export const mockProductDefinition2Duplicated: ProductDefinition = {
  id: 'Y43c1a80-4706-4657-9a69-eb58dbec475Y-mockProductDefinition2Duplicated',
  name: 'Wall3000 (2)',
  releaseName: 'Wall3000 release 2',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount2.id,
    name: mockAccount2.name,
  },
  project: {
    id: mockProject2.id,
    name: mockProject2.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [...mockProductDefinition2IProperties, ...mockProductDefinition2Parameters],
  parametersDefaults: [],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['BIM'],
      },
    },
  ],
};

export const mockProductDefinition3: ProductDefinition = {
  id: '9f45c660-69be-4529-8e2d-e963e9f94cd1-mockProductDefinition3',
  name: 'MID Shelving Unit',
  releaseName: '',
  lastUpdated: 1683162626704,
  account: {
    id: '',
    name: '',
  },
  project: {
    id: '',
    name: '',
  },
  folder: {
    id: '',
    name: '',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\MID Shelving Unit.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [
    {
      type: InputType.MULTI_VALUE_NUMERIC,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Height',
      values: [30, 42, 54, 66],
      unit: 'in',
      value: 54,
      onChange: [],
      allowCustomValue: true,
    },
    {
      type: InputType.MULTI_VALUE_TEXT,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Material',
      values: [
        '"Laminated Veneer Lumber"',
        '"Particleboard"',
        '"Ash"',
        '"Birch"',
        '"Mahogany"',
        '"Maple"',
        '"Oak, Red"',
        '"Pine"',
        '"Plywood, Finish"',
        '"Spruce"',
        '"Walnut"',
      ],
      unit: 'Text',
      value: '"Oak, Red"',
      allowCustomValue: true,
    },
    {
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Shelves_Qty',
      values: [1, 2, 3],
      unit: 'ul',
      value: 3,
      onChange: [],
      allowCustomValue: true,
    },
  ],
  parametersDefaults: [],
  rules: [
    {
      key: 'currentRule',
      code: "parameters['Height'].values = [30, 42, 54, 66];parameters['Material'].values = ['Laminated Veneer Lumber', 'Particleboard', 'Ash', 'Birch', 'Mahogany', 'Maple', 'Oak, Red', 'Pine', 'Plywood, Finish', 'Spruce', 'Walnut'];parameters['Shelves_Qty'].values = [1, 2, 3, 4];if ((parameters['Height'].value) == 30) {\n  parameters['Shelves_Qty'].values = [1];} else if ((parameters['Height'].value) == 42) {\n  parameters['Shelves_Qty'].values = [1, 2];} else if ((parameters['Height'].value) == 54) {\n  parameters['Shelves_Qty'].values = [1, 2, 3];} else if ((parameters['Height'].value) == 66) {\n  parameters['Shelves_Qty'].values = [1, 2, 3, 4];parameters['Material'].values = ['Oak, Red', 'Maple', 'Mahogany', 'Birch'];}\n",
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        category: 'Furniture',
        family: 'MID Shelving Unit',
        modelStates: ['[Primary]'],
      },
    },
    {
      type: OutputType.THUMBNAIL,
      options: {
        modelStates: ['[Primary]'],
      },
    },
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['[Primary]'],
      },
    },
  ],
  codeBlocksWorkspace: mockCodeBlocksWorkspaceMockWithConditionalMultiValueAssignments,
};

export const mockProductDefinition4: ProductDefinition = {
  id: '9f45c660-69be-4529-8e2d-e963e9f94cd2',
  name: 'MID Shelving Unit 1',
  releaseName: '',
  lastUpdated: 1683162626704,
  account: {
    id: '',
    name: '',
  },
  project: {
    id: '',
    name: '',
  },
  folder: {
    id: '',
    name: '',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\MID Shelving Unit.ipj',
  assembly: '/project2/MID Shelving Unit.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [
    {
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Height',
      values: [30, 42, 54, 66],
      unit: 'in',
      value: 54,
      onChange: [],
      allowCustomValue: true,
    },
    {
      type: InputType.TEXT,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Material',
      values: [
        '"Laminated Veneer Lumber"',
        '"Particleboard"',
        '"Ash"',
        '"Birch"',
        '"Mahogany"',
        '"Maple"',
        '"Oak, Red"',
        '"Pine"',
        '"Plywood, Finish"',
        '"Spruce"',
        '"Walnut"',
      ],
      unit: 'Text',
      value: '"Oak, Red"',
      allowCustomValue: true,
    },
    {
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Shelves_Qty',
      values: [1, 2, 3],
      unit: 'ul',
      value: 3,
      onChange: [],
      allowCustomValue: true,
    },
  ],
  parametersDefaults: [],
  rules: [
    {
      key: 'currentRule',
      code: "parameters['Height'].values = [30, 42, 54, 66];parameters['Material'].values = ['Laminated Veneer Lumber', 'Particleboard', 'Ash', 'Birch', 'Mahogany', 'Maple', 'Oak, Red', 'Pine', 'Plywood, Finish', 'Spruce', 'Walnut'];parameters['Shelves_Qty'].values = [1, 2, 3, 4];if ((parameters['Height'].value) == 30) {\n  parameters['Shelves_Qty'].values = [1];} else if ((parameters['Height'].value) == 42) {\n  parameters['Shelves_Qty'].values = [1, 2];} else if ((parameters['Height'].value) == 54) {\n  parameters['Shelves_Qty'].values = [1, 2, 3];} else if ((parameters['Height'].value) == 66) {\n  parameters['Shelves_Qty'].values = [1, 2, 3, 4];parameters['Material'].values = ['Oak, Red', 'Maple', 'Mahogany', 'Birch'];}\n",
    },
  ],
  outputs: [
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['[Primary]'],
      },
    },
    {
      type: OutputType.RFA,
      options: {
        category: 'Furniture',
        family: 'MID Shelving Unit',
        modelStates: ['[Primary]'],
      },
    },
  ],
  codeBlocksWorkspace: mockCodeBlocksWorkspaceMockWithConditionalMultiValueAssignments,
};

export const mockProductDefinitionAlreadyPublished: ProductDefinition = {
  id: '0000c660-69be-4529-8e2d-e963e9f94cd2',
  name: 'MID Shelving Already Published',
  releaseName: 'MID Shelving Already Published',
  latestContentId: 'c3e2ea91-6f4d-4462-9436-f8a183c8e510',
  lastUpdated: 1683162626704,
  account: {
    id: '',
    name: '',
  },
  project: {
    id: '',
    name: '',
  },
  folder: {
    id: '',
    name: '',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\MID Shelving Unit.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [
    {
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Height',
      values: [30, 42, 54, 66],
      unit: 'in',
      value: 54,
      onChange: [],
      allowCustomValue: true,
    },
    {
      type: InputType.NUMERIC,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Shelves_Qty',
      values: [1, 2, 3],
      unit: 'ul',
      value: 3,
      onChange: [],
      allowCustomValue: true,
    },
  ],
  parametersDefaults: [],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        category: 'Furniture',
        family: 'MID Shelving Unit',
        modelStates: ['[Primary]'],
      },
    },
    {
      type: OutputType.THUMBNAIL,
      options: {
        modelStates: ['[Primary]'],
      },
    },
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['[Master]'],
      },
    },
  ],
};

export const mockProductDefinitionWithSimpleCodeBlocks: ProductDefinition = {
  id: '6ca6948f-8646-449a-b1a6-b12ea1dbc991',
  name: 'WallWithLogic',
  releaseName: 'Wall With Logic First Release',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  drawingThumbnails: [],
  inputs: [mockBooleanInput],
  parametersDefaults: [],
  rules: [
    {
      key: 'currentRule',
      code: "parameters['EqualCheck'].value = true;",
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
  codeBlocksWorkspace: mockCodeBlockWorkspaceWithSimpleAssignment,
};

export const mockProductDefinitionWithOneNumericValue: ProductDefinition = {
  id: '6ca6948f-8646-449a-b1a6-b12ea1dbc992',
  name: 'Wall Width Min Valid',
  releaseName: 'Wall With Logic First Release',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  inputs: [mockNumericInputWithMinValidValue],
  parametersDefaults: [],
  rules: [
    {
      key: 'currentRule',
      code: "parameters['Width'].min = 10;",
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
  codeBlocksWorkspace: mockCodeBlockWorkspaceWithNumericValue,
};

export const mockProductDefinitionWithMultipleNumericValuesAndValidationRules: ProductDefinition = {
  id: '6ca6948f-8646-449a-b1a6-b12ea1dbc124',
  name: 'Wall Width Height Depth Min Invalid ',
  releaseName: 'Wall With Logic First Release',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.id,
    name: mockAccount.name,
  },
  project: {
    id: mockProject.id,
    name: mockProject.name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:\\path\\to\\folder',
  inventorProject: '\\project1\\project.ipj',
  assembly: '\\project2\\assembly.iam',
  thumbnail: thumbnailsBase64.thumbnail1,
  inputs: [mockNumericInputWithWidthMinError, mockNumericInputWithHeightMinError, mockNumericInputWithDepthMinError],
  parametersDefaults: [],
  rules: [
    {
      key: 'currentRule',
      code: "parameters['Width'].min = 10;parameters['Height'].min = 20;parameters['Depth'].min = 30;",
    },
  ],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
  codeBlocksWorkspace: mockCodeBlockWorkspaceWithMultipleInvalidNumericValues,
};

export const mockProductDefinitions: ProductDefinition[] = [
  mockProductDefinition1Duplicated,
  mockProductDefinition2Duplicated,
  mockProductDefinition1,
  mockProductDefinition2,
  mockProductDefinition3,
  mockProductDefinitionAlreadyPublished,
];
