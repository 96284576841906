import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthHandler, Environment } from 'mid-types';
import { InvalidURLError } from 'mid-utils';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';

export interface RequestOptions {
  params?: any;
  signal?: AbortSignal;
}
export interface GetRequestOptions extends RequestOptions {
  fetchBlob?: boolean;
}

export type ApiServiceResponse<T = any> = Promise<AxiosResponse<T>>;
type ApiServiceAbortableResponse<T = any> = { abort: () => void; response: ApiServiceResponse<T> };
type ExtraHeaders = { [key: string]: string };

export class ApiService {
  private axios: AxiosInstance;
  private baseURL: string;
  private token: string;
  private env: Environment;
  private extraHeaders?: ExtraHeaders;
  private authHandler?: AuthHandler;

  /**
   * @param baseURL Base url for API
   * @param token Authentication token
   * @param env mock, dev, stg, or prd
   * @param extraHeaders any extra headers to supply
   * @param authHandler function that returns an Authentication token; will be used in the request interceptor
   * to be called before every request
   */
  constructor(baseURL: string, token: string, env: Environment, extraHeaders: ExtraHeaders = {}, authHandler?: AuthHandler) {
    this.baseURL = baseURL;
    this.token = token;
    this.env = env;
    this.extraHeaders = extraHeaders;
    this.authHandler = authHandler;
    this.axios = this.createAxiosInstance();
  }

  public getEnv(): Environment {
    return this.env;
  }

  private createAxiosInstance(): AxiosInstance {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: this.baseURL,
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        ...this.extraHeaders,
      },
    };

    // create new axios instance
    const axiosInstance = axios.create(axiosConfig);

    // setup request interceptors for axios instance
    axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
      // if auth handler is provided, we use it in the request interceptor
      // to refresh token before every request.
      if (this.authHandler && config.headers) {
        const token = await this.authHandler();
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      if (config.headers) {
        // check it is dc-api or not,
        // move this code to DcApiService after we start using DcApiService for all dc-api calls
        // remove dc-api url check after moving code to DcApiService
        if (config.baseURL && config.baseURL.includes('dc.autodesk.com') && config.url) {
          const projectIdRegex = /projects\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
          const match = projectIdRegex.exec(config.url);
          if (match && match[1]) {
            const projectId = match[1];
            config.headers['Project-Id'] = projectId;
          } else {
            config.headers['Project-Id'] = 'NONE';
          }
        }
      }
      return config;
    });

    // setup response interceptors for axios instance
    axiosInstance.interceptors.response.use((response) => response, handleAxiosResponseError);

    return axiosInstance;
  }

  public get<T = any>(path: string, config?: GetRequestOptions): ApiServiceResponse<T> {
    // create request-scoped config
    const requestConfig: AxiosRequestConfig = {
      responseType: config?.fetchBlob ? 'blob' : undefined,
      params: config?.params,
      signal: config?.signal,
    };

    // call get method request-scoped config
    return this.axios.get<T>(path, requestConfig);
  }

  public post<T = any>(path: string, data?: any): ApiServiceResponse<T> {
    return this.axios.post<T>(path, data);
  }

  public put<T = any>(path: string, data?: any): ApiServiceResponse<T> {
    return this.axios.put<T>(path, data);
  }

  public patch<T = any>(path: string, data?: any): ApiServiceResponse<T> {
    return this.axios.patch<T>(path, data);
  }

  public delete(path: string, config?: RequestOptions): ApiServiceResponse {
    // create request-scoped config
    const requestConfig: AxiosRequestConfig = {
      params: config?.params,
    };
    return this.axios.delete(path, requestConfig);
  }

  public abortableGet<T = any>(path: string, config?: GetRequestOptions): ApiServiceAbortableResponse<T> {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();
    // create request-scoped config
    const requestConfig: AxiosRequestConfig = {
      responseType: config?.fetchBlob ? 'blob' : undefined,
      params: config?.params,
    };

    return {
      abort,
      response: this.axios.get<T>(path, {
        ...requestConfig,
        cancelToken: source.token,
      }),
    };
  }

  public abortablePost(path: string, data?: any): ApiServiceAbortableResponse {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();

    return {
      abort,
      response: this.axios.post(path, data, { cancelToken: source.token }),
    };
  }

  public abortablePatch(path: string, data?: any): ApiServiceAbortableResponse {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();

    return {
      abort,
      response: this.axios.patch(path, data, { cancelToken: source.token }),
    };
  }

  public abortablePut(path: string, data?: any): ApiServiceAbortableResponse {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();

    return {
      abort,
      response: this.axios.put(path, data, { cancelToken: source.token }),
    };
  }

  public abortableDelete(path: string, data?: any): ApiServiceAbortableResponse {
    const source = axios.CancelToken.source();
    const abort = () => source.cancel();

    return {
      abort,
      response: this.axios.delete(path, { data, cancelToken: source.token }),
    };
  }
}

const handleAxiosResponseError = (err: AxiosError) => {
  console.error('== Response Error : ', err);

  return Promise.reject(err);
};

export class ApiServiceFactory {
  public static createApiService(
    serviceType: ServiceTypes,
    options: {
      token: string;
      env: Environment;
      extraHeaders?: { [key: string]: string };
    },
  ): ApiService {
    const { env, token, extraHeaders } = options;
    const serviceBaseURL = this.getServiceBaseURL(serviceType, env);
    return new ApiService(serviceBaseURL, token, env, extraHeaders);
  }

  public static getServiceBaseURL(serviceType: ServiceTypes, env: Environment): string {
    const service = ServiceConfigMap[serviceType][env];
    if (!service || !service.api) {
      throw new InvalidURLError('Please provide a valid API URL.');
    }
    return service.api;
  }
}
