import { StateSetter } from '@mid-react-common/common';
import { vendorPlatforms } from 'global/constants/bim360';
import { useContext, useState } from 'react';
import { TreeNode } from '../../types/common';
import { UploadLocationProject } from '../../types/uploadLocationProject';
import AccountProjectContext from '../AccountProjectStore/AccountProject.context';

export interface UploadLocationStore {
  uploadLocationProject: UploadLocationProject | null;
  setUploadLocationProject: StateSetter<UploadLocationProject | null>;
  uploadFolderUrn: string | null;
  setUploadFolderUrn: StateSetter<string | null>;
  uploadLocationBreadcrumbs: TreeNode[];
  setUploadLocationBreadcrumbs: StateSetter<TreeNode[]>;
}

export const useUploadLocationStore = (): UploadLocationStore => {
  const { currentAccount, currentProject } = useContext(AccountProjectContext);

  const defaultUploadLocationProject: UploadLocationProject | null =
    currentAccount && currentProject
      ? {
          id: currentProject.id,
          name: currentProject.name,
          accountId: currentAccount.id,
          accountName: currentAccount.name,
          vendor: vendorPlatforms[currentProject.platform],
        }
      : null;

  const [uploadLocationProject, setUploadLocationProject] = useState<UploadLocationProject | null>(
    defaultUploadLocationProject,
  );
  const [uploadFolderUrn, setUploadFolderUrn] = useState<string | null>(null);
  const [uploadLocationBreadcrumbs, setUploadLocationBreadcrumbs] = useState<TreeNode[]>([]);

  return {
    uploadLocationProject,
    setUploadLocationProject,
    uploadFolderUrn,
    setUploadFolderUrn,
    uploadLocationBreadcrumbs,
    setUploadLocationBreadcrumbs,
  };
};
