import { StateSetter } from '@mid-react-common/common';
import { ENVIRONMENT, Environment } from 'mid-types';
import { ServiceConfigMap } from 'mid-utils';
import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import ProductContext from '../../../context/ProductStore/Product.context';
import text from '../../../global/text.json';
import { routes } from '../../../routes';
import { KeyValueTableRow } from '../../../types/KeyValueTable';
import { FolderContentRow } from '../ModelsFolderContent/ModelsFolderContent.types';

type UseModelDetailsState = {
  fileInfo: KeyValueTableRow[];
  currentlyOpenModel: FolderContentRow | null;
  openConfirmUnselect: boolean;
  openConfirmReplace: boolean;
  handleCloseButtonClick: () => void;
  handleOpenModelClick: () => void;
  handleShowCloseModelConfirmation: () => void;
  handleConfirmReplaceOpen: () => void;
  handleConfirmReplaceClose: () => void;
  handleDismissCloseModelConfirmation: () => void;
  handleCloseModel: () => void;
};

const useModelDetails = (
  folderUrn: string,
  selectedModelFolder: FolderContentRow | null,
  setSelectedModelFolder: StateSetter<FolderContentRow | null>,
  handleModelDetailsClose: () => void,
): UseModelDetailsState => {
  const { currentlyOpenModel, setCurrentlyOpenModel } = useContext(ModelSelectionContext);
  const { projectId } = useContext(AccountProjectContext);
  const { resetProductStoreState } = useContext(ProductContext);
  const [openConfirmReplace, setOpenConfirmReplace] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleConfirmReplaceOpen = () => setOpenConfirmReplace(true);
  const handleConfirmReplaceClose = () => setOpenConfirmReplace(false);

  const [openConfirmUnselect, setOpenConfirmUnselect] = useState(false);
  const handleShowCloseModelConfirmation = () => setOpenConfirmUnselect(true);
  const handleDismissCloseModelConfirmation = () => setOpenConfirmUnselect(false);

  const getModeLMVUrlLink = (
    BimDocsUrl: string,
    projectId: string | null,
    folderUrn: string,
    row: FolderContentRow | null,
  ) => {
    if (projectId && row) {
      const url = BimDocsUrl + '/' + projectId + '/folders/' + folderUrn + '/detail/viewer/items/' + row.urn;
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {row.fileName}
        </a>
      );
    }
    return row?.fileName;
  };

  const modelDetailsText = text.modelDetails;
  const currentEnv = (import.meta.env.VITE_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;
  const fileInfo: KeyValueTableRow[] = [
    {
      label: modelDetailsText.name,
      value: getModeLMVUrlLink(
        ServiceConfigMap.DOCUMENTS_MANAGER_API[currentEnv].api,
        projectId,
        folderUrn,
        selectedModelFolder,
      ),
    },
    { label: modelDetailsText.version, value: selectedModelFolder?.version },
    {
      label: modelDetailsText.lastUpdated,
      value: selectedModelFolder?.modifiedOn,
    },
  ];

  const handleOpenModelClick = () => {
    resetProductStoreState();

    setCurrentlyOpenModel(selectedModelFolder);
    navigate(`../${routes.instances.path}`);
  };

  const handleCloseButtonClick = () => {
    setSelectedModelFolder(null);

    handleModelDetailsClose();
  };

  const handleCloseModel = () => {
    // remove openModel URL parameter
    searchParams.delete('openModel');
    setSearchParams(searchParams);

    setCurrentlyOpenModel(null);
    resetProductStoreState();
  };

  return {
    fileInfo,
    currentlyOpenModel,
    openConfirmUnselect,
    openConfirmReplace,
    handleCloseButtonClick,
    handleOpenModelClick,
    handleShowCloseModelConfirmation,
    handleConfirmReplaceOpen,
    handleConfirmReplaceClose,
    handleDismissCloseModelConfirmation,
    handleCloseModel,
  };
};

export default useModelDetails;
