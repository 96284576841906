export const urlRegex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

export const urlReplacer =
  (linkColor: string) =>
  (matched: string): string => {
    let withProtocol = matched;

    if (!withProtocol.toLowerCase().startsWith('http')) {
      withProtocol = 'http://' + matched;
    }

    // We need rel="noreferrer noopener"
    // to prevent the tabnabbing phishing attack
    const newStr = `<a target="_blank" rel="noreferrer noopener" href="${withProtocol}" style="color:${linkColor};">${withProtocol}</a>`;
    return newStr;
  };
