import Divider from '@mui/material/Divider';
import ProductContext from 'context/ProductStore/Product.context';
import React, { useContext } from 'react';
import InstanceDetailsPanel from '../InstanceDetailsPanel/InstanceDetailsPanel';
import { ModelViewer } from '../ModelViewer/ModelViewer';
import ProductsInstancesDataGrid from '../ProductsInstancesDataGrid/ProductsInstancesDataGrid';
import { ExpandToggleButton, GenerateOutputsButton } from './InstancePanelComponents';
import {
  InstanceSidebarHeader,
  InstanceSidebarHeaderGroup,
  InstanceSidebarHeaderRow,
  InstancesSidebar,
  ModelViewContainer,
} from './InstancesPanel.style';
import { ProductSelectDropdown } from './ProductSelectDropdown/ProductSelectDropdown';
import { useInstancesPanel } from './useInstancesPanel';
import SelectionFilter from './InstanceSidebarHeader/SelectionFilter/SelectionFilter';

const InstancesPanel: React.FC = () => {
  const { dataGridInstances } = useContext(ProductContext);
  const {
    instancesSidebarExpanded,
    selectedProductRelease,
    outputsButtonDisabled,
    localSelectedInstances,
    selectedInstancesIds,
    dataGridColumns,
    productsLoading,
    hasDataGridInstancesBeenInitialized,
    showInstanceDetailsPanel,
    variantsList,
    selectionFilter,
    setSelectionFilter,
    handleInstancesSelection,
    handleToggleExpanded,
    handleInstanceDetailsPanelVisibility,
    handleGenerateOutputsButtonClick,
  } = useInstancesPanel();

  return (
    <>
      <InstancesSidebar expanded={instancesSidebarExpanded}>
        <InstanceSidebarHeader>
          <InstanceSidebarHeaderRow>
            <InstanceSidebarHeaderGroup>
              <ProductSelectDropdown expanded={instancesSidebarExpanded} />
              <GenerateOutputsButton onClick={handleGenerateOutputsButtonClick} disabled={outputsButtonDisabled} />
              <ExpandToggleButton expanded={instancesSidebarExpanded} onToggle={handleToggleExpanded} />
            </InstanceSidebarHeaderGroup>
          </InstanceSidebarHeaderRow>
          <SelectionFilter
            selectedInstances={localSelectedInstances}
            setSelectionFilter={setSelectionFilter}
            selectionFilter={selectionFilter}
          />
        </InstanceSidebarHeader>
        <ProductsInstancesDataGrid
          midModelInstances={dataGridInstances}
          selectedInstancesIds={selectedInstancesIds}
          handleInstancesSelection={handleInstancesSelection}
          columns={dataGridColumns}
          tableLoading={productsLoading || !hasDataGridInstancesBeenInitialized}
          selectionFilter={selectionFilter}
        />
      </InstancesSidebar>
      <Divider orientation="vertical" />
      {showInstanceDetailsPanel && selectedProductRelease && (
        <InstanceDetailsPanel
          visibleInstances={dataGridInstances}
          selectedInstancesIds={selectedInstancesIds || []}
          variants={variantsList}
          currentProductRelease={selectedProductRelease}
          handleInstanceDetailsPanelVisibility={handleInstanceDetailsPanelVisibility}
        />
      )}
      <ModelViewContainer expanded={instancesSidebarExpanded}>
        <ModelViewer />
      </ModelViewContainer>
    </>
  );
};

export default InstancesPanel;
