import { Vendors } from '@adsk/offsite-dc-sdk';
import { inject, injectable } from 'inversify';
import { chunk } from 'lodash';
import type {
  AccProject,
  AuthHandler,
  BIM360CurrentVersion,
  BIM360Derivative,
  BIM360DerivativeChild,
  BIM360Document,
  BIMAccount,
  CheckPermissionPostRequest,
  CheckPermissionPostResponse,
  DerivativesResponse,
  Environment,
  FolderContentResponse,
  FolderDMPermissionAction,
  FolderPermissionDetail,
  FolderResponse,
  FolderTreeResponse,
  FoldersResponse,
  ForgeDMProjectFolder,
  ForgeJsonApiListResponse,
  ForgeQueryResponse,
  GetFoldersArgs,
  GetSubFoldersArgs,
  Hub,
  HubProject,
  HubProjectFolderContent,
  HubProjectFolderContentResponse,
  HubProjectFolderPostRequest,
  HubProjectFolderResponse,
  HubProjectResponse,
  ProjectEntitlement,
  ProjectFolder,
  ProjectUser,
  UserAnalytics,
  UserProfile,
} from 'mid-types';
import { CHECK_PERMISSION_TYPE, COMMAND_TYPE, contentTypes, hubProjectFolderTypes } from 'mid-types';
import {
  ApiPathTypes,
  ApiPaths,
  ApiPathsConfigMap,
  BIM360AccountsFetchError,
  BIM360CurrentVersionProcessResult,
  BIM360CurrentVersionProcessState,
  BIM360FolderDocumentsFetchError,
  BIM360FoldersFetchError,
  BIM360ManifestFetchError,
  BIM360ProjectFetchError,
  BIM360ProjectsFetchError,
  ForgeDMProjectFolderTypes,
  HubProjectFolderFetchError,
  HubProjectsFetchError,
  HubsFetchError,
  ThumbnailError,
  logError,
  processAllSettledResults,
} from 'mid-utils';
import 'reflect-metadata';
import { ApiService } from '../api.service';
import { InversifyTypes } from '../inversify/inversifyTypes';
import { handleForgeApiError, normalizeIdForDataManagementV2, stripIdPrefix } from './dataManagementV2';
import forgeAPIServiceText from './text.json';
import {
  CheckHubProjectFolderPermissionsArgs,
  CheckHubProjectFolderPermissionsResult,
  DEFAULT_CHECK_PERMISSION_PAGE_SIZE,
  GetHubProjectArgs,
  GetHubProjectFolderArgs,
  GetHubProjectFolderContentsArgs,
  GetHubProjectTopFoldersArgs,
  GetHubProjectsArgs,
} from './types';

export const defaultPaginationLimit = 200;

export const getAllItemsGenerator = async function* <T>(
  apiService: ApiService,
  itemsPath: string,
  signal?: AbortSignal,
): AsyncGenerator<T[], void> {
  type ItemsResponse = ForgeQueryResponse<T>;

  const { data } = await apiService.get<ItemsResponse>(itemsPath, { signal });

  yield data.results;

  let nextUrl = data.pagination.nextUrl;

  while (nextUrl) {
    const { data: nextData } = await apiService.get<ItemsResponse>(nextUrl, { signal });

    yield nextData.results;

    nextUrl = nextData.pagination.nextUrl;
  }
};

const getAllItemsFromJsonApiGenerator = async function* <T>(
  apiService: ApiService,
  itemsPath: string,
  signal?: AbortSignal,
): AsyncGenerator<T[], void> {
  type ItemsResponse = ForgeJsonApiListResponse<T>;

  const { data } = await apiService.get<ItemsResponse>(itemsPath, { signal });

  yield data.data;

  let nextUrl = data.links?.next?.href;

  while (nextUrl) {
    const { data: nextData } = await apiService.get<ItemsResponse>(nextUrl);

    yield nextData.data;

    nextUrl = nextData.links?.next?.href;
  }
};

export const toProjectFolder = (data: ForgeDMProjectFolder): ProjectFolder => {
  const { urn, title, hidden, deleted, path, ...restOfData } = data;

  return {
    urn,
    title,
    path,
    hidden,
    projectId: restOfData.project_id,
    parentUrn: restOfData.parent_urn,
    hasSubfolders: restOfData.has_subfolders,
    deleted,
    folderType: restOfData.folder_type,
    isRoot: restOfData.is_root,
    viewOption: restOfData.view_option,
    permissionType: restOfData.permission_type,
    permissionActions: restOfData.permission_actions,
    isSystemFolder: restOfData.is_system_folder,
  };
};

/**
 * Filter callback function by permission
 * @param folder the forge DM project folder
 * @param permissionFilter One of the Forge folder permission_actions: FolderDMPermissionAction
 * @returns boolean
 */
export const filterFolderByPermission = (folder: ProjectFolder, permissionFilter: FolderDMPermissionAction): boolean =>
  folder.permissionActions.includes(permissionFilter);

@injectable()
export class ForgeApiService {
  private apiService: ApiService;

  constructor(
    @inject(InversifyTypes.ForgeApiBaseURL) baseURL: string,
    @inject(InversifyTypes.AuthHandler) authHandler: AuthHandler,
    @inject(InversifyTypes.Env) env: Environment,
  ) {
    this.apiService = new ApiService(baseURL, '', env, {}, authHandler);
  }

  async getAccounts(): Promise<BIMAccount[]> {
    try {
      const allAccountsGenerator = getAllItemsGenerator<BIMAccount>(
        this.apiService,
        `${ApiPaths.BIM360_API_PATH}/${ApiPaths.ACCOUNTS}?limit=${defaultPaginationLimit}&offset=0`,
      );
      const allAccounts: BIMAccount[] = [];

      for await (const accounts of allAccountsGenerator) {
        allAccounts.push(...accounts);
      }

      return allAccounts;
    } catch (e) {
      logError(e);
      throw new BIM360AccountsFetchError(forgeAPIServiceText.accountsLoadError);
    }
  }

  async getProjects(accountId: string): Promise<AccProject[]> {
    try {
      const allProjectsGenerator = getAllItemsGenerator<AccProject>(
        this.apiService,
        `${ApiPaths.ACC_API_PATH}/${ApiPaths.ACCOUNTS}/${accountId}/${ApiPaths.PROJECTS}?filter[status]=active&limit=${defaultPaginationLimit}&offset=0`,
      );

      const allProjects: AccProject[] = [];

      for await (const projects of allProjectsGenerator) {
        allProjects.push(...projects);
      }

      return allProjects;
    } catch (e) {
      logError(e);
      throw new BIM360ProjectsFetchError(forgeAPIServiceText.projectsLoadError);
    }
  }

  async getProjectById(projectId: string): Promise<AccProject | null> {
    try {
      const projectPath = `${ApiPaths.ACC_API_PATH}/${ApiPaths.PROJECTS}/${projectId}`;

      return (await this.apiService.get<AccProject>(projectPath)).data;
    } catch (e) {
      logError(e);
      throw new BIM360ProjectFetchError(forgeAPIServiceText.projectLoadError);
    }
  }

  async getProjectEntitlements(signal?: AbortSignal): Promise<ProjectEntitlement[]> {
    try {
      const allProjectEntitlementsGenerator = getAllItemsGenerator<ProjectEntitlement>(
        this.apiService,
        `${ApiPaths.BIM360_V2_API_PATH}/project-entitlements?fields=name,accountName,accountId,platform&filter[products]=docs,documentManagement&limit=${defaultPaginationLimit}`,
        signal,
      );

      const allProjectEntitlements: ProjectEntitlement[] = [];

      for await (const projectEntitlements of allProjectEntitlementsGenerator) {
        allProjectEntitlements.push(...projectEntitlements);
      }

      return allProjectEntitlements;
    } catch (e) {
      logError(e);
      throw new BIM360ProjectsFetchError(forgeAPIServiceText.projectsLoadError);
    }
  }

  // return the ancestor tree for a specified folder
  async getFolderTree(projectId: string, folderUrn: string): Promise<ForgeDMProjectFolder[]> {
    try {
      const url = `${
        ApiPathsConfigMap[ApiPathTypes.DM_PROJECTS_PATH][this.apiService.getEnv()].path
      }/${projectId}/folders/${folderUrn}/folder_tree?include_permission=true`;

      const {
        data: { folder_tree },
      } = await this.apiService.get<FolderTreeResponse>(url);

      return folder_tree.filter((folder) => folder.folder_type !== ForgeDMProjectFolderTypes.PLAN);
    } catch (e) {
      logError(e);
      throw new BIM360FoldersFetchError(forgeAPIServiceText.foldersTreeLoadError, { projectId, folderUrn });
    }
  }

  async getFolder(projectId: string, folderUrn: string): Promise<ProjectFolder> {
    try {
      const url = `${
        ApiPathsConfigMap[ApiPathTypes.DM_PROJECTS_PATH][this.apiService.getEnv()].path
      }/${projectId}/folders/${folderUrn}`;

      const {
        data: { attributes },
      } = await this.apiService.get<FolderResponse>(url);

      return toProjectFolder(attributes);
    } catch (e) {
      logError(e);
      throw new BIM360FoldersFetchError(forgeAPIServiceText.foldersLoadError, { projectId });
    }
  }

  async getFolders({ projectId, permissionFilter }: GetFoldersArgs): Promise<ProjectFolder[]> {
    try {
      const url = `${ApiPathsConfigMap[ApiPathTypes.DM_PROJECTS_PATH][this.apiService.getEnv()].path}/${projectId}/folders`;
      const {
        data: { folders },
      } = await this.apiService.get<FoldersResponse>(url);

      return permissionFilter
        ? folders
            .filter((folder) => folder.folder_type !== ForgeDMProjectFolderTypes.PLAN)
            .map(toProjectFolder)
            .filter((folder) => filterFolderByPermission(folder, permissionFilter))
        : folders.filter((folder) => folder.folder_type !== ForgeDMProjectFolderTypes.PLAN).map(toProjectFolder);
    } catch (e) {
      logError(e);
      throw new BIM360FoldersFetchError(forgeAPIServiceText.foldersLoadError, { projectId });
    }
  }

  async getSubFolders({ projectId, folderUrn, permissionFilter }: GetSubFoldersArgs): Promise<ProjectFolder[]> {
    try {
      const url = `${
        ApiPathsConfigMap[ApiPathTypes.DM_PROJECTS_PATH][this.apiService.getEnv()].path
      }/${projectId}/folders/${folderUrn}`;
      const {
        data: { folders },
      } = await this.apiService.get<FoldersResponse>(url);

      return permissionFilter
        ? folders.map(toProjectFolder).filter((folder) => filterFolderByPermission(folder, permissionFilter))
        : folders.map(toProjectFolder);
    } catch (e) {
      logError(e);
      throw new BIM360FoldersFetchError(forgeAPIServiceText.subfoldersLoadError, { projectId, folderUrn });
    }
  }

  async getFolderContent(projectId: string, folderUrn: string): Promise<BIM360Document[]> {
    const isCurrentDocVersionReady = (currentVersion: BIM360CurrentVersion): Boolean =>
      currentVersion.process_state === BIM360CurrentVersionProcessState.PROCESSING_COMPLETE &&
      currentVersion.process_result === BIM360CurrentVersionProcessResult.PROCESSING_SUCCESS;

    try {
      const url = `${
        ApiPathsConfigMap[ApiPathTypes.DM_PROJECTS_PATH][this.apiService.getEnv()].path
      }/${projectId}/folders/${folderUrn}/documents`;

      const {
        data: { documents },
      } = await this.apiService.get<FolderContentResponse>(url);

      return documents.filter((doc: BIM360Document) => isCurrentDocVersionReady(doc.current_version));
    } catch (e) {
      logError(e);
      throw new BIM360FolderDocumentsFetchError(forgeAPIServiceText.folderContentLoadError, { projectId, folderUrn });
    }
  }

  async getDerivatives(documentId: string): Promise<BIM360Derivative[]> {
    try {
      const manifestUrl = `${ApiPaths.MODEL_DERIVATIVE_PATH}/${documentId}/manifest`;

      const {
        data: { derivatives },
      } = await this.apiService.get<DerivativesResponse>(manifestUrl);

      return derivatives;
    } catch (e) {
      logError(e);
      throw new BIM360ManifestFetchError(forgeAPIServiceText.folderManifestLoadError, { documentId });
    }
  }

  async getThumbnail(documentId: string): Promise<Blob> {
    try {
      const derivatives = await this.getDerivatives(documentId);

      const svfDerivatives = derivatives.find((derivative: BIM360Derivative) => derivative.outputType === 'svf');

      const svf3DDerivatives = svfDerivatives?.children.find(
        (svfDerivative: BIM360DerivativeChild) => svfDerivative.role === '3d',
      );

      const guidPath = svf3DDerivatives && svf3DDerivatives.guid && `&guid=${svf3DDerivatives.guid}`;

      const thumbnailUrl = `${ApiPaths.DOCUMENT_THUMBNAIL_PATH}/${documentId}?type=large${guidPath}`;
      const { data } = await this.apiService.get<BlobPart>(thumbnailUrl, {
        fetchBlob: true,
      });

      return new Blob([data], { type: 'image/png' });
    } catch (e: unknown) {
      logError(e);
      throw new ThumbnailError(forgeAPIServiceText.documentThumbnailLoadError, {});
    }
  }

  async createNewFolder(projectId: string, parentUrn: string, title: string): Promise<ProjectFolder> {
    const apiPath = ApiPathsConfigMap[ApiPathTypes.DM_PROJECTS_PATH][this.apiService.getEnv()].path;
    const foldersPath = `${apiPath}/${projectId}/folders`;

    const payload = {
      title,
      parent_folder_urn: parentUrn,
    };
    const response = await this.apiService.post<ForgeDMProjectFolder>(foldersPath, payload);

    return toProjectFolder(response.data);
  }

  async getUserProfile(): Promise<UserProfile> {
    const userPath = `${ApiPaths.USERPROFILE_API_PATH}/users/@me`;
    const { data } = await this.apiService.get<UserProfile>(userPath);

    return data;
  }

  async getUserAnalyticsId(userId: string): Promise<string> {
    const analyticsPath = `${ApiPaths.IDENTITY_API_PATH}/users/${userId}/analytics`;
    const {
      data: { analyticsId },
    } = await this.apiService.get<UserAnalytics>(analyticsPath);

    return analyticsId;
  }

  async getUserInfoInProject(projectId: string, userId: string): Promise<ProjectUser> {
    const userInfoInProjectPath = `${ApiPaths.ACC_API_PATH}/projects/${projectId}/users/${userId}`;

    const { data } = await this.apiService.get<ProjectUser>(userInfoInProjectPath);

    return data;
  }

  async getHubProjectTopFolders({
    hubId,
    projectId,
    vendor,
  }: GetHubProjectTopFoldersArgs): Promise<HubProjectFolderContent[]> {
    try {
      const transformedHubId = normalizeIdForDataManagementV2(hubId, vendor);
      const transformedHubProjectId = normalizeIdForDataManagementV2(projectId, vendor);
      const topFoldersPath = `${ApiPaths.DM_V2_PROJECT_PATH}/hubs/${transformedHubId}/projects/${transformedHubProjectId}/topFolders`;

      const {
        data: { data },
      } = await this.apiService.get<HubProjectFolderContentResponse>(topFoldersPath);

      return data.filter((folder) => folder.attributes.extension.data.folderType !== ForgeDMProjectFolderTypes.PLAN);
    } catch (e) {
      logError(e);
      throw new HubProjectFolderFetchError(forgeAPIServiceText.foldersLoadError, { projectId, hubId });
    }
  }

  async getHubProjectFolderContents({
    projectId,
    folderId,
    vendor,
  }: GetHubProjectFolderContentsArgs): Promise<HubProjectFolderContent[]> {
    const transformedHubProjectId = normalizeIdForDataManagementV2(projectId, vendor);

    const path = `${ApiPaths.DM_V2_DATA_PATH}/projects/${transformedHubProjectId}/folders/${folderId}/contents`;

    const {
      data: { data },
    } = await this.apiService.get<HubProjectFolderContentResponse>(path);

    return data;
  }

  async createHubProjectSubfolder(
    projectId: string,
    parentFolderId: string,
    folderName: string,
    vendor: Vendors,
  ): Promise<HubProjectFolderResponse> {
    try {
      const transformedHubProjectId = normalizeIdForDataManagementV2(projectId, vendor);

      const path = `${ApiPaths.DM_V2_DATA_PATH}/projects/${transformedHubProjectId}/folders`;

      const payload: HubProjectFolderPostRequest = {
        jsonapi: {
          version: '1.0',
        },
        data: {
          type: contentTypes.FOLDERS,
          attributes: {
            name: folderName,
            extension: {
              type: vendor === Vendors.FUSIONTEAM ? hubProjectFolderTypes.FUSION : hubProjectFolderTypes.BIM360_OR_ACC,
              version: '1.0',
            },
          },
          relationships: {
            parent: {
              data: {
                type: contentTypes.FOLDERS,
                id: parentFolderId,
              },
            },
          },
        },
      };
      const {
        data: { data, links, jsonapi },
      } = await this.apiService.post<HubProjectFolderResponse>(path, payload);
      return { data, links, jsonapi };
    } catch (e) {
      return handleForgeApiError(e);
    }
  }

  async getHubs(signal?: AbortSignal): Promise<Hub[]> {
    try {
      const path = `${ApiPaths.DM_V2_PROJECT_PATH}/hubs?page[limit]=${defaultPaginationLimit}`;

      const allHubsGenerator = await getAllItemsFromJsonApiGenerator<Hub>(this.apiService, path, signal);

      const allHubs: Hub[] = [];

      for await (const hubs of allHubsGenerator) {
        allHubs.push(...hubs);
      }

      return allHubs.map((hub) => ({
        ...hub,
        id: stripIdPrefix(hub.id),
      }));
    } catch (e) {
      logError(e);
      throw new HubsFetchError(forgeAPIServiceText.hubsLoadError);
    }
  }

  async getHubProjects({ hubId, hubType, signal }: GetHubProjectsArgs): Promise<HubProject[]> {
    try {
      const transformedHubId = normalizeIdForDataManagementV2(hubId, hubType);

      const path = `${ApiPaths.DM_V2_PROJECT_PATH}/hubs/${transformedHubId}/projects?page[limit]=${defaultPaginationLimit}`;

      const allHubProjectsGenerator = await getAllItemsFromJsonApiGenerator<HubProject>(this.apiService, path, signal);

      const allHubProjects: HubProject[] = [];

      for await (const hubProjects of allHubProjectsGenerator) {
        allHubProjects.push(...hubProjects);
      }

      return allHubProjects.map((hubProject) => ({
        ...hubProject,
        id: stripIdPrefix(hubProject.id),
      }));
    } catch (e) {
      logError(e);
      throw new HubProjectsFetchError(forgeAPIServiceText.hubProjectsLoadError, { hubId });
    }
  }

  async checkHubProjectFolderPermissions({
    projectId,
    vendor,
    folderUrns,
    permissionActions,
    pageSize = DEFAULT_CHECK_PERMISSION_PAGE_SIZE,
  }: CheckHubProjectFolderPermissionsArgs): Promise<CheckHubProjectFolderPermissionsResult> {
    const transformedHubProjectId = normalizeIdForDataManagementV2(projectId, vendor);
    const path = `${ApiPaths.DM_V2_DATA_PATH}/projects/${transformedHubProjectId}/commands`;

    const groupedFolderUrnArray: string[][] = chunk(folderUrns, pageSize);

    const checkPermissionPromises: Promise<FolderPermissionDetail[]>[] = groupedFolderUrnArray.map(
      async (folderUrns: string[]) => {
        const payload: CheckPermissionPostRequest = {
          jsonapi: {
            version: '1.0',
          },
          data: {
            type: COMMAND_TYPE,
            attributes: {
              extension: {
                type: CHECK_PERMISSION_TYPE,
                version: '1.0.0',
                data: {
                  requiredActions: permissionActions,
                },
              },
            },
            relationships: {
              resources: {
                data: folderUrns.map((urn: string) => ({
                  type: contentTypes.FOLDERS,
                  id: urn,
                })),
              },
            },
          },
        };

        const {
          data: { data },
        } = await this.apiService.post<CheckPermissionPostResponse>(path, payload);

        return data.attributes.extension.data.permissions;
      },
    );

    const checkPermissionPromiseResults = await Promise.allSettled<FolderPermissionDetail[]>(checkPermissionPromises);

    return processAllSettledResults(checkPermissionPromiseResults);
  }

  async getHubProject({ hubId, projectId, vendor, signal }: GetHubProjectArgs): Promise<HubProjectResponse> {
    try {
      const transformedHubId = normalizeIdForDataManagementV2(hubId, vendor);
      const transformedHubProjectId = normalizeIdForDataManagementV2(projectId, vendor);

      const path = `${ApiPaths.DM_V2_PROJECT_PATH}/hubs/${transformedHubId}/projects/${transformedHubProjectId}`;

      const {
        data: { data, jsonapi, links },
      } = await this.apiService.get<HubProjectResponse>(path, { signal });

      return { data: { ...data!, id: stripIdPrefix(data!.id) }, jsonapi, links };
    } catch (e: unknown) {
      return handleForgeApiError(e);
    }
  }

  async getHubProjectFolder({
    projectId,
    folderId,
    vendor,
    signal,
  }: GetHubProjectFolderArgs): Promise<HubProjectFolderResponse> {
    try {
      const transformedHubProjectId = normalizeIdForDataManagementV2(projectId, vendor);

      const path = `${ApiPaths.DM_V2_DATA_PATH}/projects/${transformedHubProjectId}/folders/${folderId}`;

      const { data } = await this.apiService.get<HubProjectFolderResponse>(path, { signal });

      return data;
    } catch (e: unknown) {
      return handleForgeApiError(e);
    }
  }
}
