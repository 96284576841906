import { useState } from 'react';
import { ActionsContainer, StyledText } from './ExpandableTextSection.styles';
import { urlRegex, urlReplacer } from './ExpandableTextSection.utils';
import { useTheme } from '@mui/material';
import domPurify from 'dompurify';
import commonText from '../../common.text.json';
import dataTestIds from '../../tests/helpers/dataTestIds';
import Box from '@mui/material/Box';
import LinkButton from '../LinkButton/LinkButton';
import VerticalSeparator from '../VerticalSeparator/VerticalSeparator';

const expandableTextSectionText = commonText.expandableTextSection;
const DEFAULT_CUTOFF_LENGTH = 150;

export interface ExpandableTextSectionProps {
  content: string;
  textCutoffLength?: number;
  className?: string;
  actionsBar?: JSX.Element;
}

export const ExpandableTextSection: React.FC<ExpandableTextSectionProps> = ({
  content,
  textCutoffLength = DEFAULT_CUTOFF_LENGTH,
  className,
  actionsBar,
}): JSX.Element => {
  const [textSectionExpanded, setTextSectionExpanded] = useState<boolean>(false);
  const theme = useTheme();
  const linkColor = theme.colors.primary.autodeskBlue[400];
  const sanitizedContent = domPurify.sanitize(content);
  const contentLargerThanCutoff = sanitizedContent.length > textCutoffLength;
  const truncatedContent = contentLargerThanCutoff
    ? textSectionExpanded
      ? sanitizedContent
      : `${sanitizedContent.slice(0, textCutoffLength)}...`
    : sanitizedContent;
  const modifiedContent: string = truncatedContent.replace(urlRegex, urlReplacer(linkColor)).replace(/\n/g, '<br>');

  return (
    <Box>
      <StyledText
        data-testid={dataTestIds.expandableTextSection.mainContent}
        dangerouslySetInnerHTML={{ __html: modifiedContent }}
        className={className}
      ></StyledText>
      {contentLargerThanCutoff ? (
        <ActionsContainer>
          <LinkButton
            onClick={() => setTextSectionExpanded((currentValue) => !currentValue)}
            data-testid={dataTestIds.expandableTextSection.expandCollapseContentButton}
          >
            {textSectionExpanded ? expandableTextSectionText.showLess : expandableTextSectionText.showMore}
          </LinkButton>

          {actionsBar && <VerticalSeparator />}
          {actionsBar}
        </ActionsContainer>
      ) : (
        <ActionsContainer>{actionsBar}</ActionsContainer>
      )}
    </Box>
  );
};
